export const COVID_19_POLICY = `https://help.heydayskincare.com/categories/covid-19-SyTT1urWK`
export const FIRST_TIMER_FORM = `https://shopapp.heydayskincare.com/intake/welcome`
export const CANCELLATION_POLICY = `https://www.heydayskincare.com/pages/service-product-policies`
export const GENERAL_REMINDER = `https://help.heydayskincare.com/en_us/what-is-your-age-minimum-to-receive-facials-rkGrgQdOd`
export const MEMBERSHIP_AGREEMENT = `https://www.heydayskincare.com/pages/membership-agreement`
export const FAQ_LINK = `https://help.heydayskincare.com/`
export const SEND_MAIL = `mailto:hello@heydayskincare.com`
export const APP_STORE_DOWNLOAD_LINK =
  'https://apps.apple.com/us/app/heyday-skincare/id992014636'
export const HEYDAY_SKIN_CARE = 'heydayskincare.com'
