import { useContext } from 'react'
import { ReactComponent as MapPin } from '../../../assets/icons/map-pin.svg'
import { ReactComponent as RoundCheckMark } from '../../../assets/icons/roundcheckmark.svg'
import { ReactComponent as DateIcon } from '../../../assets/icons/date-icon.svg'
import { ReactComponent as Call } from '../../../assets/icons/call.svg'
import { ReactComponent as Mail } from '../../../assets/icons/mail.svg'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import './confirmedAppointment.scss'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { AppContext } from '../../../providers/context/App'
import moment from 'moment'
import ICalendarLink from 'react-icalendar-link'
import {
  COVID_19_POLICY,
  FIRST_TIMER_FORM,
  GENERAL_REMINDER,
} from '../../../utils/constants/StaticLinks'
import { getLocationBanner } from '../../../utils/helper-functions/locationBanner'
import { ShareAppointmentCard } from '../shareAppointmentCard'
import { getFacialType } from 'utils/helper-functions'
import GetTheApp from '../getTheApp'

const facialSteps = [
  {
    label: '2 Weeks Before',
    description: `Refrain from BOTOX, fillers, chemical peels, or any skin procedures like mircroneedling, microblading, dermaplaning, or microdermabrasion.`,
  },
  {
    label: '2 Days Before',
    description: `Don't exfoliate, wax, or apply RX-strength topicals.`,
  },
  {
    label: '1 Day Before',
    description: `Don't clean shave. Come in rocking that 5 o'clock shadow.`,
  },
]

const laserFacialSteps = [
  {
    label: '2 Weeks Before',
    description: 'Refrain from BOTOX, fillers, or any advanced exfoliation like chemical peels, microdermabrasion, microneedling or microblading.',
  },
  {
    label: '1 Week Before',
    description: 'Discontinue use of any exfoliants, waxing or Rx topicals.',
  },
  {
    label: '1-3 Days Before',
    description: 'Shave (or book a Dermaplane treatment) to remove any facial hair or peach fuzz for optimal results.',
  },
]

const ConfirmedAppointmentContent = () => {
  const { appMasterData } = useContext(AppContext)
  const isLaserFacial = appMasterData.isLaserFacial
  const steps = isLaserFacial ? laserFacialSteps : facialSteps

  const calendarEvent = {
    title: `${getFacialType(appMasterData.selectedFacial)} ${
      !appMasterData.selectedEsthetician.isAny
        ? `with ${
            appMasterData.selectedEsthetician.staff.firstName
          } ${appMasterData.selectedEsthetician.staff.lastName.charAt(0)}`
        : ''
    } `,
    description: `${getFacialType(appMasterData.selectedFacial)} ${
      !appMasterData.selectedEsthetician.isAny
        ? `with ${
            appMasterData.selectedEsthetician.staff.firstName
          } ${appMasterData.selectedEsthetician.staff.lastName.charAt(0)}`
        : ''
    } `,
    startTime: appMasterData.selectedTime.startTime,
    endTime: moment(appMasterData.selectedTime.startTime)
      .add(50, 'minutes')
      .format(),
    location: `${appMasterData.location.address.streetAddress} ${appMasterData.location.address.city}, ${appMasterData.location.address.state} ${appMasterData.location.address.zipCode}`,
  }
  const locationInformation = appMasterData.location.contact

  return (
    <>
      <div className="success-screen-content">
        <div className="content-wrapper success-screen-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RoundCheckMark className="success-screen-header-icon" />
              <div className="success-header-wrapper">
                <Typography component="div" className="success-screen-header">
                  You’re All Set!
                </Typography>

                {appMasterData.cart.purchasedMembership && (
                  <Typography component="p" className="first-member-message">
                    Enjoy your first member facial.
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <ImageList>
                <ImageListItem cols={12} rows={12}>
                  <img
                    src={getLocationBanner(appMasterData.location.name)}
                    alt={'container-img'}
                    loading="lazy"
                  />
                </ImageListItem>
              </ImageList>
            </Grid>

            <Grid item xs={12}>
              <h3 className="success-screen-name">
                {getFacialType(appMasterData.selectedFacial)}{' '}
                {!appMasterData.selectedEsthetician.isAny
                  ? `with ${
                      appMasterData.selectedEsthetician.staff.firstName
                    } ${appMasterData.selectedEsthetician.staff.lastName.charAt(
                      0
                    )}`
                  : ''}
              </h3>
              <Typography component="div" className="success-screen-date">
                {moment(appMasterData.selectedDate).format('dddd, MMM DD')} @{' '}
                {moment
                  .tz(
                    appMasterData.selectedTime.startTime,
                    appMasterData.location.tz
                  )
                  .format('h:mm A')}
              </Typography>
              <div>
                <MapPin className="success-screen-location-icon" />
                <div className="body-1 success-screen-location">
                  {appMasterData.location.name}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className="add-to-calender">
              {/* @ts-ignore */}
              <ICalendarLink event={calendarEvent} className="ical-link">
                <Button
                  variant="outlined"
                  startIcon={<DateIcon />}
                  className="success-screen-btn"
                  color="primary"
                >
                  Add to Calendar
                </Button>
              </ICalendarLink>
            </Grid>
            {/* not showing share appointment card if user purchased membership along with appointment */}
            {/* it'll show share appointment card if only appointment was purchased */}
            {!appMasterData.isLaserFacial &&
              !appMasterData.cart.purchasedMembership && (
                <Grid item xs={12} className="add-to-calender">
                  <ShareAppointmentCard />
                </Grid>
              )}
            {/* showing get the app card if user purchased membership along with appointment */}
            {appMasterData.cart.purchasedMembership && (
              <Grid item xs={12} className="add-to-calender">
                <GetTheApp />
              </Grid>
            )}
            <Grid item xs={12} className="divider-wrapper">
              <Divider className="divider" />
            </Grid>

            <Grid item xs={12}>
              <div className="body-1 success-screen-address1">
                Heyday - {appMasterData.location.name}
              </div>
              <div className="body-1 success-screen-address2">
                {`${appMasterData.location.address.streetAddress} ${appMasterData.location.address.city}, ${appMasterData.location.address.state} ${appMasterData.location.address.zipCode}`}
              </div>
              {!!locationInformation && (
                <div className="body-1 success-screen-icon-box">
                  <Call className="success-screen-icon" />
                  <a
                    href={`tel:${locationInformation?.phone}`}
                    className="body-1 success-screen-link-text"
                  >
                    {locationInformation?.phone}
                  </a>
                </div>
              )}
              {!!locationInformation && (
                <div className="body-1 success-screen-icon-box">
                  <Mail className="success-screen-icon" />
                  <a
                    href={`mailto:${locationInformation?.email}`}
                    className="body-1 success-screen-link-text"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {locationInformation?.email}
                  </a>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="content-body tertiary-background">
        <Grid container spacing={2} className="success-content">
          <Grid item xs={12} className="success-curve" />
          <Grid item xs={12} className="success-content-wrapper">
            <Typography component="div" className="success-screen-content-head">
              The countdown begins! Let’s get your skin ready. 💫
            </Typography>
            <Grid item xs={12} px={1}>
              <Stepper orientation="vertical" className="stepper-wrapper">
                {steps.map((step, index) => (
                  <Step active={true} key={step.label}>
                    <StepLabel className="stepper-label">
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <div className="step-content">{step.description}</div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} className="success-reminder">
              <Typography className="success-reminder-head">
                General Reminders
              </Typography>

              <Grid item xs={12} className="success-reminder-container">
                <div className="body-1 success-reminder-content">
                  Please arrive a few minutes before your appointment so we may
                  get you checked in. Late arrivals may result in shortened
                  appointments-after 10 minutes, your appointment will be
                  canceled and you will incur a $25 cancellation fee.
                </div>
              </Grid>
              <Grid item xs={12} className="success-reminder-container">
                <div className="body-1 success-reminder-content">
                  New clients, please complete our{' '}
                  <Link
                    href={FIRST_TIMER_FORM}
                    target={`_blank`}
                    rel="noreferrer"
                  >
                    {' '}
                    {isLaserFacial ? 'first-time facial' : 'first-timer'} form
                  </Link>{' '}
                  {isLaserFacial ? 
                    "ahead of time so we can learn about your skin. First-time Laser clients must complete the Laser Waiver you'll receive by email, prior to arrival."
                  : 
                    'ahead of time for a speedy check-in process.'
                  }
                </div>
              </Grid>

              {!isLaserFacial &&
                <>
                  <Grid item xs={12} className="success-reminder-container">
                    <div className="body-1 success-reminder-content">
                      Keep your neck clear of high collars and jewelry, because
                      necks need love too.
                    </div>
                  </Grid>
                  <Grid item xs={12} className="success-reminder-container">
                    <div className="body-1 success-reminder-content">
                      Know your home care routine and any medications you’re taking.
                      Your Skin Therapist will want to know as much as possible.
                    </div>
                  </Grid>
                  <Grid item xs={12} className="success-reminder-container">
                    <div className="body-1 success-reminder-content">
                      Age Minimum: We don't perform facials on anyone 14 years old
                      or under, and recommend seeing a dermatologist for any serious
                      skin issues. Please read our full age policy{' '}
                      <Link
                        href={GENERAL_REMINDER}
                        target="_blank"
                        rel="noreferrer"
                      >
                        here.
                      </Link>
                    </div>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2} className="footer-wrapper">
        <Grid item xs={12} className="footer-content-wrapper">
          <Grid item xs={12}>
            <div className="body-1 footer-head">Cancellation Policy</div>
            <div className="footer-content-container">
              <div className="body-2 footer-content">
                Need to cancel? No problem! Appointments can be canceled online
                up to 24 hours in advance. Cancellations within 24 hours of an
                appointment may incur a $25 fee and must be cancelled by calling
                a Heyday shop.
              </div>
              <div className="body-2 footer-content">
                If you’re running late, we kindly request you call. Those
                arriving 10+ minutes after their start time will not be serviced
                and will incur a $25 fee.
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography className="footer-head">COVID-19 Policy</Typography>
            <div className="body-2 footer-content">
              For an up-to-date version of our COVID-19 policy, check{' '}
              <Link href={COVID_19_POLICY} target="_blank" rel="noreferrer">
                here.
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ConfirmedAppointmentContent
