import React from 'react'
import './portableText.scss'
import ReactPortableText from 'react-portable-text'

export default function PortableText({
  content,
}: {
  readonly content: object[]
}) {
  return (
    <div className="portable-text-wrapper">
      <ReactPortableText content={content} />
    </div>
  )
}
