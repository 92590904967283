import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  addBookableItemToCart,
  cartBookableTimes,
  clearCart,
} from '../../services/Cart'

import {
  Esthetician,
  EstheticianList,
} from '../../utils/types/estheticianTypes'
import { AppContext } from './App'
import { mapSeries, series } from 'async'
import moment from 'moment-timezone'
import _ from 'lodash'
import { useLoading } from './Loading'
import { getMyAppointments } from '../../services/User'
import { MyAppointmentsResponse } from '../../utils/types/userTypes'
import { getSelectedDate } from 'utils/helper-functions'

interface IEstheticianContextProp {
  estheticianMasterData: EstheticianList
  updateData: (_: Partial<EstheticianList>) => void
}

export const anyEsth = {
  id: 'any',
  duration: 0,
  price: 0,
  isAny: true,
  staff: {
    firstName: 'Any Esthetician',
    lastName: '',
    bio: '',
    id: '',
  },
}

const initialEstheticianMasterData = {
  estheticians: [],
  defaultEsthetician: [],
  previousBookedEsthetician: [],
  filteredEstheticians: [],
  estheticianTimeslots: {},
  selectedDate: new Date(),
}

export const EstheticianListContext = createContext<IEstheticianContextProp>({
  estheticianMasterData: initialEstheticianMasterData,
  updateData: () => { },
})

const EstheticianListProvider = ({ children }: { children: ReactNode }) => {
  const { appMasterData } = useContext(AppContext)
  const { setLoading } = useLoading()

  // will take opening date if its greater than current date and selected date
  // else it'll take date which is selected in ui
  const selectedDate = getSelectedDate(
    appMasterData.selectedDate,
    appMasterData.location
  )

  const [estheticianMasterData, setEstheticianMasterData] =
    useState<EstheticianList>({
      ...initialEstheticianMasterData,
      estheticians: appMasterData.availableEstheticians,
      selectedDate: selectedDate,
    })

  const updateData = (estheticianStateUpdate: Partial<EstheticianList>) => {
    setEstheticianMasterData((prev) => ({ ...prev, ...estheticianStateUpdate }))
  }

  const getEstheticianTimeslots = useCallback(
    (estheticians: Esthetician[]) => {
      setLoading(true)
      const allEstheticianIds = estheticians.map((d) => d.id)
      mapSeries(
        allEstheticianIds,
        (staffVariantId, callback) => {
          series(
            [
              (callback) => {
                addBookableItemToCart(
                  appMasterData.cart.cartId,
                  appMasterData.category.categoryId,
                  staffVariantId !== 'any' ? staffVariantId : ''
                )
                  .then((d) => {
                    if (staffVariantId !== 'any') return
                    const promises = appMasterData.selectedEnhancements.map((sE) => {
                      return addBookableItemToCart(
                        appMasterData.cart.cartId,
                        sE.id,
                        ''
                      )
                    })
                    return Promise.all(promises)
                  }).then(() => callback(null, true)
                  )
                  .catch((e) => {
                    setLoading(false)
                    console.error(e)
                  })
              },
              (callback) => {
                cartBookableTimes(
                  appMasterData.cart.cartId,
                  moment(selectedDate).format('YYYY-MM-DD'),
                  appMasterData.location.tz
                )
                  .then((bookableItems) => {
                    callback(null, bookableItems.cartBookableTimes)
                  })
                  .catch((e) => console.error(e))
              },
              (callback) => {
                clearCart(appMasterData.cart.cartId)
                  .then(() => {
                    callback(null, true)
                  })
                  .catch((e) => console.error(e))
              },
            ],
            (err, slotResults) => {
              callback(null, {
                [staffVariantId]: slotResults?.length ? slotResults[1] : [],
              })
            }
          )
        },
        (err, results) => {
          if (err) {
            console.error(err)
          } else {
            const finalData: any = {}
            _.forEach(results, (value: any) => {
              finalData[Object.keys(value)[0]] = value[Object.keys(value)[0]]
            })
            updateData({ estheticianTimeslots: finalData })
          }
          setLoading(false)
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      appMasterData.cart.cartId,
      appMasterData.category.categoryId,
      estheticianMasterData.selectedDate,
      setLoading,
    ]
  )

  const setDefaultEsthetician = useCallback(
    async (staffVariants: Esthetician[]) => {
      const defaultVariants = []
      try {
        if (!appMasterData.isLaserFacial) {
          // get previously booked appointment
          const query = `startAt <= '${moment(new Date()).format(
            'YYYY-MM-DD'
          )}' AND locationId = '${appMasterData.location.locationId}'`
          // Fetch appointments only for logged in users
          if (appMasterData.user.token) {
            const bookedApppointment: MyAppointmentsResponse =
              await getMyAppointments(query)
            if (bookedApppointment.myAppointments) {
              let appointmentEsth = _.map(
                bookedApppointment.myAppointments.edges,
                (d) => {
                  const appointmentServices = d.node.appointmentServices[0]
                  const getStaffVarientId =
                    _.find(
                      staffVariants,
                      (d) => d.staff.id === appointmentServices.staff.id
                    )?.id ?? ''
                  return {
                    id: getStaffVarientId,
                    duration: appointmentServices.duration,
                    price: appointmentServices.duration,
                    isAny: false,
                    lastSeen: appointmentServices.startAt,
                    staff: appointmentServices.staff,
                  }
                }
              )
              appointmentEsth = _.uniqBy(appointmentEsth, 'id')
              // Remove inactive esthetician (with no id) from prev appointments
              _.remove(appointmentEsth, (d) => d.id === '' && !d.isAny)

              const staffId = appointmentEsth.map((d) => d.staff.id)

              // Update previously booked appointment in context
              updateData({ previousBookedEsthetician: appointmentEsth })
              defaultVariants.push(...appointmentEsth)
              // Update esthetician list based on format - previously booked, Any & rest others
              _.remove(staffVariants, (d) => staffId.includes(d.staff.id))
              staffVariants.unshift(...appointmentEsth)
              // Remove inactive esthetician (with no id) from the list
              _.remove(staffVariants, (d) => d.id === '' && !d.isAny)
              updateData({ estheticians: staffVariants })
            }
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        // Set any esthetician
        defaultVariants.push(anyEsth)
        updateData({ defaultEsthetician: defaultVariants })
        if (
          appMasterData.filteredEstheticians.length === 0 &&
          !appMasterData.isLaserFacial
        ) {
          getEstheticianTimeslots(defaultVariants)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appMasterData.location.locationId]
  )

  const getEstheticians = useCallback(async () => {
    setLoading(true)
    await setDefaultEsthetician(estheticianMasterData.estheticians)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appMasterData.cart.cartId,
    appMasterData.category.categoryId,
    setDefaultEsthetician,
    setLoading,
  ])

  useEffect(() => {
    getEstheticians().catch((e) => console.error(e))
  }, [getEstheticians])

  useEffect(() => {
    getEstheticianTimeslots(
      estheticianMasterData.filteredEstheticians.length > 0
        ? estheticianMasterData.filteredEstheticians
        : estheticianMasterData.defaultEsthetician
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getEstheticianTimeslots,
    estheticianMasterData.filteredEstheticians,
    estheticianMasterData.selectedDate,
  ])

  const value = useMemo(() => {
    return {
      estheticianMasterData,
      updateData,
    }
  }, [estheticianMasterData])

  return (
    <EstheticianListContext.Provider value={value}>
      {children}
    </EstheticianListContext.Provider>
  )
}

export default EstheticianListProvider
