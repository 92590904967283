import React, { ReactElement } from 'react'
import './toast.scss'
import { SnackbarKey, SnackbarProvider, SnackbarAction } from 'notistack'
import { IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'

const ToastContainer = (props: { children: React.ReactNode }): ReactElement => {
  const notistackRef = React.createRef<SnackbarProvider>()
  const onClickDismiss = (key: SnackbarKey) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key)
    }
  }
  const action: SnackbarAction = (k) => (
    <IconButton
      onClick={onClickDismiss(k)}
      className="toast-close-btn"
      data-testid="toast-close-btn"
    >
      <Close />
    </IconButton>
  )
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={action}
      hideIconVariant
    >
      {props.children}
    </SnackbarProvider>
  )
}

export default ToastContainer
