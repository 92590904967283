import { getMyMemberships } from 'services/User'

export const getActiveMembershipDetails = async () => {
  try {
    const memberships = await getMyMemberships()
    if (memberships.myMemberships.edges.length)
      return memberships.myMemberships.edges.find(
        (obj) => obj.node.status === 'ACTIVE'
      )
  } catch (e) {
    console.error(e)
  }
}

export const getMyMembershipVouchers = async () => {
  try {
    const myActiveMembership = await getActiveMembershipDetails()
    if (myActiveMembership) return myActiveMembership.node.vouchers
  } catch (e) {
    console.error(e)
  }
}
