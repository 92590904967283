import { AppData, FacebookEvents } from '../utils/types/appTypes'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

/**
 *
 * GOOGLE AND FACEBOOK ANALYTICS
 *
 */

/**
 *
 * @param page path name
 *
 * This function will add path for tracking
 */
export const TrackPage = (page: string) => {
  ReactGA.set({ page })
  ReactGA.pageview(page)

  ReactPixel.track('PageView')
}

/**
 *
 * @param action Event Action
 * @param label Event Label
 */
const GaEvent = (action: string, label: string) => {
  ReactGA.event({
    category: 'WebBooking',
    action: action,
    label: label,
  })
}

/**
 *
 * @param title Event Title
 * @param data Data
 *
 */
const FbPixelEvent = (title: string, data: Partial<FacebookEvents>) => {
  ReactPixel.track(title, data)
}

/**
 * ViewContent is to be fired when the widget is launched
 */
export const ViewContentAnalytics = () => {
  GaEvent('ViewContent', 'web-booking-widget')

  const data = {
    content_name: 'Facial',
    content_type: 'BookAFacial',
    content_category: 'Facial Service',
  }
  FbPixelEvent('ViewContent', data)
}

/**
 *
 * AddToCart is to be launched when the Continue button is pushed once a location is selected
 */
export const AddToCartAnalytics = () => {
  const data = {
    content_type: 'BookAFacial',
    content_category: 'Facial Service',
  }
  FbPixelEvent('AddToCart', data)
  GaEvent('AddToCart', 'facial')
}

/**
 *
 * FindATimeDateChange is to be launched when a time is selected
 */
export const FindATimeDateChangeAnalytics = (date: string) => {
  const data = {
    date,
    content_category: 'Facial Service',
  }
  FbPixelEvent('FindATimeDateChange', data)
  GaEvent('FindATimeDateChange', 'date_time_selection')
}

/**
 *
 * @param userInfo
 *
 * InitiateCheckout is to be fired when the confirm appointment screen is loaded
 */
export const InitiateCheckoutAnalytics = (appMasterData: AppData) => {
  const data = {
    value: appMasterData.cart.summary.subtotal / 100,
    num_items: 1,
    currency: 'USD',
    content_name: 'Facial',
  }
  FbPixelEvent('InitiateCheckout', data)
  GaEvent('InitiateCheckout', 'review')
}

/**
 *
 * Purchase is to be fired when the appointment is confirmed through the Blvd API
 */
export const PurchaseAnalytics = (appMasterData: AppData) => {
  const data = {
    value: appMasterData.cart.summary.subtotal / 100,
    num_items: 1,
    currency: 'USD',
    content_type: 'BookAFacial',
  }
  FbPixelEvent('Purchase', data)
  ReactGA.event({
    category: 'WebBooking',
    action: 'Purchase',
    label: 'appointment_confirmation',
    value: appMasterData.cart.summary.subtotal / 100,
  })
}

export const triggerPurchaseConversionEvent = (transId?: string) => {
  window.gtag('event', 'conversion', {
    send_to: 'AW-933034885/utcxCKWmyswZEIX387wD',
    transaction_id: transId ?? '',
  })
}

export const triggerFranchiseeConversionEvent = (transId?: string) => {
  window.gtag2('event', 'conversion', {
    send_to: 'AW-10851896489/ZBfhCJ2tyswZEKmZy7Yo',
    transaction_id: transId ?? '',
  })
}
