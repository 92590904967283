import { GRAPHQL_ENDPOINT } from '../utils/constants/EndpointUrls'
import { AXIOS_METHODS } from '../utils/constants/Enums'
import { Axios } from './Axios'
import {
  AddBookableItemToCartPayload,
  AddBookableItemToCartResponse,
  AddCardToCartPayload,
  AddCardToCartResponse,
  CartBookableTimesPayload,
  CartBookableTimesResponse,
  CheckoutCartPayload,
  CheckoutCartResponse,
  ClearCartPayload,
  ClearCartResponse,
  ClientInformation,
  CreateCartPayload,
  CreateCartResponse,
  GetCartPayload,
  GetCartResponse,
  RemoveBookableItemToCartPayload,
  RemoveBookableItemToCartResponse,
  ReserveTimeslotToCartPayload,
  ReserveTimeslotToCartResponse,
  SelectCardMethodPayload,
  SelectCardMethodResponse,
  UpdateBookableItemToCartPayload,
  UpdateBookableItemToCartResponse,
  UpdateClientInfoPayload,
  UpdateClientInfoResponse,
} from '../utils/types/cartTypes'
import { getLocalstorageItem } from '../utils/helper-functions'
import { BOULEVARD_PUBLIC_API_TOKEN } from '../utils/constants/Helpers'
const getBoulevardClientHeader = () => {
  return {
    Authorization: `Basic ${getLocalstorageItem(BOULEVARD_PUBLIC_API_TOKEN)}`,
  }
}

/*
 * Mutations
 */

const createCartMutation = `mutation CreateCart($locationId: ID!){
  createCart(input: {
    locationId: $locationId
  }) {
    cart {
      id

      availableCategories {
        name

        availableItems {
          __typename
          id
          name
          listPrice
        }
      }
    }
  }
}`

const addBookableItemToCartQuery = `mutation AddCartSelectedBookableItem($input: AddCartSelectedBookableItemInput!){
  addCartSelectedBookableItem(input: $input) {
    cart {
      id
      selectedItems {
        id
        addons {
          id
          name
        }
      }
      summary {
        discountAmount
        gratuityAmount
        paymentMethodRequired
        subtotal
        taxAmount
        total
        depositAmount
        deposit
      }
    }
  }
}`

const removeBookableItemToCartQuery = `mutation RemoveCartSelectedItem($input: RemoveCartSelectedItemInput!){
  removeCartSelectedItem(input: $input) {
    cart {
      id
      selectedItems {
        id
      }
      summary {
        discountAmount
        gratuityAmount
        paymentMethodRequired
        subtotal
        taxAmount
        total
        depositAmount
        deposit
      }
    }
  }
}`

const updateBookableItemToCartQuery = `mutation UpdateCartSelectedBookableItem($input: UpdateCartSelectedBookableItemInput!){
  updateCartSelectedBookableItem(input: $input) {
    cart {
      id
    }
  }
}`

const clearCartQuery = `mutation CartClear($cartId: ID!){
  cartClear(input: {
    id: $cartId
  }) {
    cart {
      id
    }
  }
}`

const addCardToCartQuery = `mutation AddCartCardPaymentMethod($input: AddCartCardPaymentMethodInput){
  addCartCardPaymentMethod(input: $input) {
    cart {
      id
      selectedItems {
        id
        availablePaymentMethods {
          ... on CartItemCardPaymentMethod {
            id
            name
            cardBrand
          }
        }
      }
    }
  }
}`

const reserveTimeslotToCartQuery = `mutation ReserveCartBookableItems($input: ReserveCartBookableItemsInput!){
  reserveCartBookableItems(input: $input) {
    cart {
      id
    }
  }
}`

const checkoutCartQuery = `mutation CheckoutCart($input: CheckoutCartInput!){
  checkoutCart(input: $input) {
    cart {
      id
      completedAt
      errors {
        code
        description
        message
      }
    }
    appointments {
      appointmentId
    }
  }
}`

const updateClientInfoQuery = `mutation UpdateCart($input: UpdateCartInput!){
  updateCart(input: $input) {
    cart {
      id
    }
  }
}`

const selectCardMethodQuery = `mutation SelectCartPaymentMethod($input: SelectCartPaymentMethodInput!){
  selectCartPaymentMethod(input: $input) {
    cart {
      id
    }
  }
}`

/*
 * Query
 */

const getCartQuery = `query GetCart($cartId: ID!, $itemId: ID!) {
	cart(id: $cartId) {
		id

		availableCategories {
			name

			availableItems {
        __typename
				id
				name
        listPrice
			}
		}

		selectedItems {
			id
			availablePaymentMethods {
				... on CartItemVoucherPaymentMethod {
					availableCount
					__typename
				}
			}
			discountAmount
			discountCode
			# discountName
			item {
				id
				name
				... on CartAvailableBookableItem {
					listDuration
					listPriceRange {
						max
						min
						variable
					}
				}
			}
			price
			selectedPaymentMethod {
				__typename
				... on CartItemCardPaymentMethod {
					cardBrand
					cardLast4
				}
			}
			__typename
			... on CartBookableItem {
				guest {
					...CartGuestFragment
				}
				selectedOptions {
					id
					name
					priceDelta
				}
				selectedStaffVariant {
					id
					staff {
						displayName
						id
					}
				}
				startTime
			}
			... on CartGiftCardItem {
				giftCardDesign {
					...CartItemGiftCardDesignFragment
				}
				emailFulfillment {
					...CartItemEmailFulfillmentFragment
				}
			}
		}

		availableItem(id: $itemId) {
			id

			... on CartAvailableBookableItem {
				name
				description
				optionGroups {
					id
					name
					description
					minLimit
					maxLimit
					options {
						id
						name
						description
						priceDelta
						groupId
					}
				}
				staffVariants {
					id
					price
					duration

					staff {
						firstName
						lastName
						bio
						id
					}
				}
			}
		}
    summary {
      discountAmount
    #   estimatedDiscountAmountAtCheckout
    #   estimatedGratuityAmountAtCheckout
    #   estimatedSubtotalAtCheckout
    #   estimatedTaxAmountAtCheckout
    #   estimatedTotalAtCheckout
      gratuityAmount
      paymentMethodRequired
      subtotal
      taxAmount
      total
      depositAmount
      deposit
    }
	}
}
fragment CartGuestFragment on CartGuest {
	firstName
	id
	label
	lastName
	number
}

fragment CartItemGiftCardDesignFragment on CartItemGiftCardDesign {
	backgroundColor
	id
	image
}

fragment CartItemEmailFulfillmentFragment on CartItemEmailFulfillment {
	deliveryDate
	messageFromSender
	recipientEmail
	recipientName
	senderName
}`

const getCartBookableTimesQuery = `query CartBookableTimes($cartId: ID!, $searchDate:Date!, $tz: Tz){
  cartBookableTimes(
    id: $cartId,
    searchDate: $searchDate,
    tz: $tz
  ) {
    id
    score
    startTime
  }
}`

/*
 * API calls
 */

export const createCart = async (locationId: string) => {
  const payload: CreateCartPayload = {
    query: createCartMutation,
    variables: {
      locationId,
    },
  }
  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    CreateCartPayload,
    CreateCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const getCart = async (cartId: string, itemId: string) => {
  const payload: GetCartPayload = {
    query: getCartQuery,
    variables: {
      cartId,
      itemId,
    },
  }
  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    GetCartPayload,
    GetCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const addBookableItemToCart = async (
  cartId: string,
  itemId: string,
  itemStaffVariantId?: string
) => {
  const payload: AddBookableItemToCartPayload = {
    query: addBookableItemToCartQuery,
    variables: {
      input: {
        id: cartId,
        itemId,
      },
    },
  }

  if (itemStaffVariantId) {
    payload.variables.input.itemStaffVariantId = itemStaffVariantId
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    AddBookableItemToCartPayload,
    AddBookableItemToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const removeBookableItemToCart = async (
  cartId: string,
  itemId: string
) => {
  const payload: RemoveBookableItemToCartPayload = {
    query: removeBookableItemToCartQuery,
    variables: {
      input: {
        id: cartId,
        itemId,
      },
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    RemoveBookableItemToCartPayload,
    RemoveBookableItemToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const updateBookableItemToCart = async (
  cartId: string,
  itemId: string,
  itemOptionIds?: Array<string>,
  itemStaffVariantId?: string
) => {
  const payload: UpdateBookableItemToCartPayload = {
    query: updateBookableItemToCartQuery,
    variables: {
      input: {
        id: cartId,
        itemId,
      },
    },
  }

  if (itemStaffVariantId) {
    payload.variables.input.itemStaffVariantId = itemStaffVariantId
  }

  if (itemOptionIds?.length) {
    payload.variables.input.itemOptionIds = itemOptionIds
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    UpdateBookableItemToCartPayload,
    UpdateBookableItemToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const cartBookableTimes = async (
  cartId: string,
  searchDate: string,
  tz: string = 'America/Los_Angeles'
) => {
  const payload: CartBookableTimesPayload = {
    query: getCartBookableTimesQuery,
    variables: {
      cartId,
      searchDate: searchDate,
      tz,
    },
  }
  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    CartBookableTimesPayload,
    CartBookableTimesResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const clearCart = async (cartId: string) => {
  const payload: ClearCartPayload = {
    query: clearCartQuery,
    variables: {
      cartId,
    },
  }
  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    ClearCartPayload,
    ClearCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const addCardToCart = async (cartId: string, token: string) => {
  const payload: AddCardToCartPayload = {
    query: addCardToCartQuery,
    variables: {
      input: {
        id: cartId,
        token,
        select: true,
      },
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    AddCardToCartPayload,
    AddCardToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const reserveTimeslotToCart = async (
  cartId: string,
  bookableTimeId: string
) => {
  const payload: ReserveTimeslotToCartPayload = {
    query: reserveTimeslotToCartQuery,
    variables: {
      input: {
        id: cartId,
        bookableTimeId,
      },
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    ReserveTimeslotToCartPayload,
    ReserveTimeslotToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const checkoutCart = async (cartId: string) => {
  const payload: CheckoutCartPayload = {
    query: checkoutCartQuery,
    variables: {
      input: {
        id: cartId,
      },
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    CheckoutCartPayload,
    CheckoutCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data?.checkoutCart?.cart?.errors?.length === 0) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const updateClientInfo = async (
  cartId: string,
  clientInfo: ClientInformation,
  clientMessage: string
) => {
  const payload: UpdateClientInfoPayload = {
    query: updateClientInfoQuery,
    variables: {
      input: {
        id: cartId,
        clientInformation: clientInfo,
        clientMessage: clientMessage,
      },
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    UpdateClientInfoPayload,
    UpdateClientInfoResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const selectCardMethod = async (
  cartId: string,
  paymentMethodId: string
) => {
  const payload: SelectCardMethodPayload = {
    query: selectCardMethodQuery,
    variables: {
      input: {
        id: cartId,
        paymentMethodId,
      },
    },
  }
  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    SelectCardMethodPayload,
    SelectCardMethodResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
