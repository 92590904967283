import React, { useEffect, useRef } from 'react'
import './loader.scss'
import { Box } from '@mui/material'
import loadingData from '../../../utils/lottie/loading_spinner_primaryAccent.json'
import { useLoading } from '../../../providers/context/Loading'
import Lottie, { LottieRef } from 'lottie-react'

const Loader = () => {
  const ref: LottieRef = useRef(null)
  const { loading } = useLoading()

  useEffect(() => {
    if (!ref?.current) {
      return
    }
    if (loading) {
      ref.current.play()
    } else {
      ref.current.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, ref.current])

  return (
    <Box
      className="app-loader"
      sx={{
        display: loading ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie
        lottieRef={ref}
        className={'loading-lottie'}
        autoPlay={false}
        loop
        animationData={loadingData}
      />
    </Box>
  )
}

export default React.memo(Loader)
