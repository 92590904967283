import { TextareaAutosize } from '@mui/material'
import React, { FC, ReactElement, useContext } from 'react'
import { AppointmentConfirmationContext } from '../../../providers/context/AppointmentConfirmation'
import { NoteState } from '../../../utils/types/appointmentTypes'
import './appointment.scss'

const AppointmentNote: FC<{}> = (): ReactElement => {
  const { updateData } = useContext(AppointmentConfirmationContext)
  const [values, setValues] = React.useState<NoteState>({
    note: '',
  })

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ note: event.target.value })
    updateData({ note: event.target.value })
  }

  return (
    <div className="personal-note">
      <div className="title">
        Include a personal note?{' '}
        <span className="optional-text">(optional)</span>
      </div>
      <TextareaAutosize
        className="note-textarea"
        minRows={4}
        aria-label="personal note"
        value={values.note}
        onChange={handleChange}
      />
    </div>
  )
}

export default React.memo(AppointmentNote)
