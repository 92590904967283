import { useContext, useRef, useState, useEffect } from 'react'
import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  format,
  getWeek,
  isPast,
  isSameDay,
  isSameMonth,
  lastDayOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
  isBefore,
  isThisWeek,
  isToday,
} from 'date-fns'
import './calendarView.scss'
import { ReactComponent as RightArrowIcon } from '../../../assets/icons/rightarrowicon.svg'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/leftarrowicon.svg'
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/downarrowicon.svg'
import { ReactComponent as UpArrowIcon } from '../../../assets/icons/uparrowicon.svg'
import { EstheticianListContext } from '../../../providers/context/EstheticianList'
import { AppContext } from '../../../providers/context/App'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useSwipeable } from 'react-swipeable'
import { getOpeningDate } from '../../../utils/helper-functions'

const CalendarView = () => {
  const { appMasterData, updateData: appUpdateData } = useContext(AppContext)
  const [currentMonth, setCurrentMonth] = useState(
    appMasterData?.selectedDate
      ? new Date(appMasterData?.selectedDate)
      : new Date()
  )
  const [, setCurrentWeek] = useState(getWeek(currentMonth))
  const [selectedDate, setSelectedDate] = useState(
    new Date(appMasterData?.selectedDate)
  )
  const [monthView, setMonthView] = useState(false)
  const { updateData } = useContext(EstheticianListContext)

  const openingDate = getOpeningDate(appMasterData.location)

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      monthView ? changeMonthHandle('next') : changeWeekHandle('next')
    },
    onSwipedRight: () => {
      monthView ? changeMonthHandle('prev') : changeWeekHandle('prev')
    },
  })
  const calendarElem = useRef<HTMLDivElement>()

  const changeMonthHandle = (btnType: string) => {
    if (btnType === 'prev') {
      if (
        (isBefore(subMonths(currentMonth, 1), new Date()) &&
          !isSameDay(subMonths(currentMonth, 1), new Date())) ||
        (openingDate && isBefore(subMonths(currentMonth, 1), openingDate))
      ) {
        return false
      }
      setCurrentMonth(subMonths(currentMonth, 1))
    }
    if (btnType === 'next') {
      setCurrentMonth(addMonths(currentMonth, 1))
    }
  }

  const changeWeekHandle = (btnType: string) => {
    if (btnType === 'prev') {
      if (
        isBefore(selectedDate, new Date()) ||
        (openingDate && isBefore(selectedDate, addWeeks(openingDate, 1))) ||
        isThisWeek(selectedDate)
      ) {
        return false
      }
      setCurrentMonth(subWeeks(currentMonth, 1))
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)))
      onDateClickHandle(
        new Date(moment(selectedDate).subtract(7, 'days').format())
      )
    }
    if (btnType === 'next') {
      setCurrentMonth(addWeeks(currentMonth, 1))
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)))
      onDateClickHandle(new Date(moment(selectedDate).add(7, 'days').format()))
    }
  }

  const onDateClickHandle = (day: Date) => {
    setSelectedDate(day)
    setCurrentMonth(day)
    appUpdateData({ selectedDate: day })
    updateData({ selectedDate: day })
    if (monthView) {
      setMonthView(!monthView)
    }
  }

  useEffect(() => {
    if (openingDate) {
      setSelectedDate(openingDate)
      setCurrentMonth(openingDate)
      updateData({ selectedDate: openingDate })
      appUpdateData({ selectedDate: openingDate })
    } else {
      updateData({ selectedDate: appMasterData?.selectedDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHeader = () => {
    const dateFormat = 'MMMMMM yyyy'
    return (
      <div className="header row flex-middle">
        <div className="col col-start header_icon">
          <div
            style={{ width: '25px' }}
            className="icon"
            onClick={() => changeMonthHandle('prev')}
          >
            <LeftArrowIcon />
          </div>
        </div>
        <div className="col col-center">
          <span className="selected-month">
            {format(currentMonth, dateFormat)}
          </span>
        </div>
        <div className="col col-end header_icon">
          <div
            className="icon"
            style={{ width: '25px' }}
            onClick={() => changeMonthHandle('next')}
          >
            <RightArrowIcon />
          </div>
        </div>
      </div>
    )
  }
  const renderDays = () => {
    const dateFormat = 'EEEEE'
    const days = []
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 0 })
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={uuidv4()}>
          <span className="days-name">
            {format(addDays(startDate, i), dateFormat)}
          </span>
        </div>
      )
    }
    return <div className="days row">{days}</div>
  }
  const renderCells = () => {
    const startDate: number | Date = startOfWeek(currentMonth, {
      weekStartsOn: 0,
    })
    const endDate: number | Date = lastDayOfWeek(currentMonth, {
      weekStartsOn: 0,
    })
    const dateFormat: string = 'dd'
    const rows: any[] = []
    let days: any[] = []
    let day: Date = startDate
    let formattedDate: any = ''
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day
        days.push(
          <div
            className={`col cell ${
              (!openingDate && isPast(cloneDay) && !isToday(cloneDay)) ||
              (openingDate && isBefore(cloneDay, openingDate))
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              onDateClickHandle(cloneDay)
            }}
            onKeyDown={() => {
              onDateClickHandle(cloneDay)
            }}
            key={i}
          >
            <span
              className={`number ${
                isSameDay(day, selectedDate) ? 'selected' : ''
              }`}
            >
              {formattedDate}
            </span>
          </div>
        )
        day = addDays(day, 1)
      }

      rows.push(
        <div className="row" key={uuidv4()}>
          {days}
        </div>
      )
      days = []
    }
    return <div className="body">{rows}</div>
  }
  const renderFooter = () => {
    return (
      <>
        <div className="header row flex-middle">
          {!monthView && (
            <div className="col col-start">
              <div
                className="icon"
                onClick={() =>
                  monthView
                    ? changeMonthHandle('prev')
                    : changeWeekHandle('prev')
                }
              >
                <LeftArrowIcon />
              </div>
            </div>
          )}

          <div className="curser-type" onClick={() => setMonthView(!monthView)}>
            <span className="selected-date">
              {format(selectedDate, 'EEEE, LLLL dd')}
            </span>
            {monthView}
            {monthView ? (
              <UpArrowIcon className="arrow-icon" />
            ) : (
              <DownArrowIcon className="arrow-icon" />
            )}
          </div>
          {!monthView && (
            <div
              className="col col-end"
              onClick={() => changeWeekHandle('next')}
            >
              <div className="icon">
                <RightArrowIcon />
              </div>
            </div>
          )}
        </div>
        {monthView && (
          <div
            onClick={() => setMonthView(!monthView)}
            className="calendar-overlay"
          ></div>
        )}
      </>
    )
  }

  const getDatesForMonth = () => {
    const startOfTheSelectedMonth: number | Date = startOfMonth(currentMonth)
    const endOfTheSelectedMonth: number | Date = endOfMonth(currentMonth)
    const startDate: number | Date = startOfWeek(startOfTheSelectedMonth)
    const endDate: number | Date = endOfWeek(endOfTheSelectedMonth)

    let currentDate = startDate

    const allWeeks = []

    while (currentDate <= endDate) {
      allWeeks.push(
        generateDatesForCurrentWeek(currentDate, selectedDate, currentMonth)
      )
      currentDate = addDays(currentDate, 7)
    }

    return <div className="body">{allWeeks}</div>
  }

  const generateDatesForCurrentWeek = (
    date: Date,
    selectedDate: Date,
    activeDate: Date
  ) => {
    let currentDate = date
    const week = []
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate
      week.push(
        <div
          className={`col cell ${
            isSameMonth(currentDate, activeDate) ? '' : 'inactiveDay'
          } ${
            (!openingDate && isPast(cloneDate) && !isToday(cloneDate)) ||
            (openingDate && isBefore(cloneDate, openingDate))
              ? 'disabled'
              : ''
          }`}
          onClick={() => {
            onDateClickHandle(cloneDate)
          }}
          onKeyDown={() => {
            onDateClickHandle(cloneDate)
          }}
          key={`week-${cloneDate}-${day}`}
        >
          <span
            className={`number ${
              isSameDay(currentDate, selectedDate) ? 'selected' : ''
            }
          `}
          >
            {isSameMonth(currentDate, activeDate) && format(currentDate, 'dd')}
          </span>
        </div>
      )
      currentDate = addDays(currentDate, 1)
    }
    return <div className="row">{week}</div>
  }

  const calRefPassthrough = (el: HTMLDivElement) => {
    handlers.ref(el)
    calendarElem.current = el
  }

  return (
    <div
      className={`calendar-custom-height ${
        monthView ? 'calender-container' : ''
      }`}
    >
      <div className={`calendar ${!monthView ? 'customPadding' : ''}`}>
        {monthView && renderHeader()}
        {renderDays()}
        <div {...handlers} ref={calRefPassthrough} className="cell-view">
          {monthView ? getDatesForMonth() : renderCells()}
        </div>
        {renderFooter()}
      </div>
    </div>
  )
}

export default CalendarView
