import { object, ref, Schema, string, ValidationError } from 'yup'
import { SIGNUP_MESSAGES } from '../constants/Messages'
import { REGEX } from '../constants/regex'

type ValidatorType = <T extends object>(
  schema: Schema,
  data: T
) => Promise<{ success: boolean; errors: T }>

const validator: ValidatorType = async (schema, data) => {
  const validatonData = Object.keys(data).reduce(
    (acc, key) => {
      acc[key] = ''
      return acc
    },
    {} as Record<string, string>
  )
  return await schema
    .validate(data, { abortEarly: false })
    .then(() => ({ success: true, errors: validatonData as typeof data }))
    .catch((err: ValidationError) => {
      err.inner.forEach((error) => {
        if (error.message && error.path && !validatonData[error.path])
          validatonData[error.path] = error.message
      })
      return { success: false, errors: validatonData as typeof data }
    })
}

export const signupSchema = object({
  emailId: string()
    .required(SIGNUP_MESSAGES.REQUIRED)
    .email(SIGNUP_MESSAGES.INVALID_EMAIL),
  firstName: string()
    .trim()
    .required(SIGNUP_MESSAGES.REQUIRED)
    .matches(REGEX.NAME, SIGNUP_MESSAGES.INVALID_NAME),
  lastName: string()
    .trim()
    .required(SIGNUP_MESSAGES.REQUIRED)
    .matches(REGEX.NAME, SIGNUP_MESSAGES.INVALID_NAME),
  phoneNumber: string()
    .trim()
    .required(SIGNUP_MESSAGES.REQUIRED)
    .matches(REGEX.PHONE_NUMBER, SIGNUP_MESSAGES.PHONE_NUMBER_INVALID),
  password: string()
    .trim()
    .required(SIGNUP_MESSAGES.REQUIRED)
    .min(5, SIGNUP_MESSAGES.PASSWORD_INVALID),
  confirmPassword: string().equals(
    [ref('password')],
    SIGNUP_MESSAGES.PASSWORD_NOT_MATCH
  ),
})

export const loginSchema = object({
  email: string()
    .required(SIGNUP_MESSAGES.REQUIRED)
    .email(SIGNUP_MESSAGES.INVALID_EMAIL),
  password: string().trim().required(SIGNUP_MESSAGES.REQUIRED),
})

export const emailSchema = string().email(SIGNUP_MESSAGES.INVALID_EMAIL)

export default validator
