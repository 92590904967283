import React, { useContext, useState } from 'react'
import { AppContext } from '../../../providers/context/App'
import './appointment.scss'
import { Alert, Dialog, Button } from '@mui/material'
import { removeBookableItemToCart } from 'services/Cart'
import {
  ENHANCEMENT_REMOVED_MESSAGE,
  GENERAL_ERROR_MESSAGE,
} from 'utils/constants/Messages'
import { useSnackbar } from 'notistack'
import { useLoading } from 'providers/context/Loading'
const AppointmentEnhancementList = () => {
  const { updateData, appMasterData } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const [deleteModalOpen, setDeleteModalOpen] = useState('')
  const { setLoading } = useLoading()
  const purchasedMembership = appMasterData.cart.purchasedMembership
  const removeEnhancement = async (id: string) => {
    setLoading(true)
    const newEnhancements = appMasterData.selectedEnhancements.filter(
      (enhancement) => enhancement.cartItemId !== id
    )
    try {
      const removeEnhancementFromCart = await removeBookableItemToCart(
        appMasterData.cart.cartId,
        id
      )
      if (removeEnhancementFromCart.removeCartSelectedItem?.cart.id) {
        if (newEnhancements.length === 0) {
          updateData({
            selectedEnhancements: newEnhancements,
            enhancement: false,
          })
        } else {
          updateData({ selectedEnhancements: newEnhancements })
        }
        enqueueSnackbar(ENHANCEMENT_REMOVED_MESSAGE, { variant: 'success' })
      }
    } catch (error) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, { variant: 'error' })
    } finally {
      setDeleteModalOpen('')
      setLoading(false)
    }
  }

  const removeMembership = () => {
    updateData({
      cart: {
        ...appMasterData.cart,
        purchasedMembership: false,
      },
    })
  }

  return (
    <div className="selected-enhancements">
      {(!!appMasterData.selectedEnhancements.length || purchasedMembership) && (
        <div className="enhancements-list">
          <span className="selected-enhancement-label">
            {appMasterData.cart.purchasedMembership
              ? 'Selected items'
              : 'Selected enhancements'}
          </span>
          {appMasterData?.selectedEnhancements?.map((item) => (
            <Alert
              className="custom-alert body-1"
              icon={false}
              onClose={() => {
                setDeleteModalOpen(item.cartItemId as string)
              }}
              id={item.id}
              key={item.id}
            >
              {item.titleVisible || item.name}
            </Alert>
          ))}
          {purchasedMembership && (
            <Alert
              className="custom-alert body-1"
              icon={false}
              onClose={removeMembership}
              id={'purchased-membership'}
            >
              Monthly Membership
            </Alert>
          )}
        </div>
      )}
      <Dialog open={!!deleteModalOpen} className="delete-enhancement-dialog">
        <div className="delete-enhancement-dialog-text">
          <h3 className="h3">Remove Enhancement?</h3>
          <span className="body-1">
            Are you sure you want to remove this from your appointment booking?
          </span>
        </div>
        <div className="delete-enhancement-dialog-buttons">
          <Button
            onClick={() => {
              removeEnhancement(deleteModalOpen).catch((e) => console.error(e))
            }}
            className="delete-enhancement-confirm-button"
          >
            Remove Enhancement
          </Button>
          <Button
            onClick={() => setDeleteModalOpen('')}
            className="delete-enhancement-cancel-button"
          >
            Keep Enhancement
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default React.memo(AppointmentEnhancementList)
