import React, { useContext } from 'react'
import { AppContext } from '../../../providers/context/App'
import './appointment.scss'

const AppointmentMap = () => {
  const { appMasterData } = useContext(AppContext)
  return (
    <div className="map-section" data-testid="appointment-map">
      {appMasterData.location.mapImg && (
        <img src={appMasterData.location.mapImg} className="map-img" alt="" />
      )}
      <div className="address-text">{`${appMasterData.location.address.streetAddress} ${appMasterData.location.address.city}, ${appMasterData.location.address.state} ${appMasterData.location.address.zipCode}`}</div>
    </div>
  )
}

export default React.memo(AppointmentMap)
