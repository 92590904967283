import GetApp from 'assets/icons/get-app.svg'
import { ReactComponent as AppIcon } from 'assets/icons/apple.svg'
import { Button } from '@mui/material'
import { APP_STORE_DOWNLOAD_LINK } from 'utils/constants/StaticLinks'
import './getTheApp.scss'

const GetTheApp = () => {
  return (
    <div className="get-app-container">
      <div className="text-container">
        <h3 className="title">Get the App?</h3>
        <p className="body1">
          Maximize your membership, book facials, & shop products.
        </p>
        <a
          href={APP_STORE_DOWNLOAD_LINK}
          target="_blank"
          rel="noreferrer"
          data-testid="app-store-link"
        >
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className="btn"
            startIcon={<AppIcon />}
          >
            Download Now
          </Button>
        </a>
      </div>
      <>
        <img src={GetApp} className="image" alt="app-img" loading="lazy" />
      </>
    </div>
  )
}

export default GetTheApp
