import axios, { AxiosError } from 'axios'
import { AXIOS_METHODS } from '../utils/constants/Enums'
import { BlvdError } from 'utils/types/cartTypes'

interface AxiosResponseType<DataType> {
  success: boolean
  data: DataType
  errors?: BlvdError[]
  message: string
  token: string
}
interface AxiosBasicHeaders {
  Accept?: string
  'Content-Length'?: string
  'User-Agent'?: string
  'Content-Encoding'?: string
  Authorization?: string
  'Content-Type'?: string
  'X-Shopify-Storefront-Access-Token'?: string
}
export class AxiosClass {
  token: string | undefined = undefined
  headers = {} as AxiosBasicHeaders
  statusCode: number = 0

  setAuthToken(token: string) {
    this.token = token
    return this
  }
  setHeaders(headers: AxiosBasicHeaders) {
    this.headers = headers
    return this
  }

  async callApi<PayloadType, DataType>(
    method: AXIOS_METHODS,
    url: string,
    params?: PayloadType,
    dataSet?: PayloadType
  ): Promise<AxiosResponseType<DataType>> {
    try {
      const headers: Record<string, string> = {
        Authorization: `Basic ${this.token}`,
        ...this.headers,
      }
      if (!this.headers.Authorization) {
        delete headers.Authorization
      }

      const response = await axios({
        method: method,
        url: `${url}${params ?? ''}`,
        headers: headers,
        data: dataSet,
      })

      const message = (response?.data?.errors || [])[0]?.message
      if (message) throw new Error(message)

      return response.status === 204 ? { data: true } : response.data
    } catch (e) {
      const error = e as AxiosError
      return Promise.reject({
        error,
        statusCode: error?.response?.status ?? 500,
      })
    }
  }
}

export const Axios = new AxiosClass()
