import { useState, useContext } from 'react'

import { services } from 'utils/constants/services'
import { conditionalClasses, formattedCost } from 'utils/helper-functions'
import { Facial } from 'utils/types/appTypes'
import { MEMBERSHIP_MAX_FACIAL_DISCOUNT, MEMBERSHIP_LASER_FACIAL_PRICE } from 'utils/constants/Helpers'
import { AppContext } from 'providers/context/App'

interface FacialCardProps {
  selectedFacial: string
  facial: Facial
  onSelectFacial: (facial: Facial) => void
}
const FacialCard = ({
  selectedFacial,
  facial,
  onSelectFacial,
}: FacialCardProps) => {

  const { appMasterData } = useContext(AppContext)
  const [collapse, setCollapse] = useState(false)
  const facialService = services.find((service) => service.name === facial.name)

  return (
    <div
      className={`facial-card ${
        selectedFacial === facial.name && 'facial-selected'
      }`}
      key={facial.name}
      onClick={() => onSelectFacial(facial)}
    >
      <div className="facial-details">
        <div>
          <h3 className="facial-name">{facial.name}</h3>
          <p className="facial-description">
            {collapse ? facialService?.long : facialService?.short}
            {!collapse && (
              <>
                {'.. '}
                <span
                  className="option-more-button"
                  onClick={() => setCollapse(!collapse)}
                >
                  More
                </span>
              </>
            )}
          </p>
        </div>
        <div className='facial-price'>
          <div className={
            conditionalClasses(appMasterData.userInfo.is_member, conditionalClasses(!facialService?.isMembershipPrice, '', 'line-through', ''), '', 'original-price')}>
            {formattedCost(facial.listPrice, false, 100)}
          </div>
          {!facialService?.hideMemberPrice &&
            <div className={conditionalClasses(appMasterData.userInfo.is_member, 'member-price', 'non-member', '')}>
              <span>
                {facialService?.isLaser ?
                  formattedCost(MEMBERSHIP_LASER_FACIAL_PRICE, false, 1)
                :
                  formattedCost(
                    Math.max(
                      (+facial.listPrice) - MEMBERSHIP_MAX_FACIAL_DISCOUNT,
                      8500
                    )
                  , false
                  )
                }
              </span>
              {!appMasterData.userInfo.is_member && <span>MEMBER</span>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default FacialCard
