import { createContext, useContext, useMemo, useState } from 'react'

interface ILoadingContextProp {
  loading: boolean
  setLoading: (_: Partial<boolean>) => void
}

const LoadingContext = createContext<ILoadingContextProp>({
  loading: false,
  setLoading: () => {},
})

export const LoadingProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [loading, setLoading] = useState(false)
  const value = useMemo(() => {
    return { loading, setLoading }
  }, [loading])
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

export function useLoading() {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
