import React from 'react'
import './appointment.scss'
import { Link } from 'react-router-dom'

import { FACIALS_ROUTE } from 'utils/constants/Helpers'

const ShowNewUserMessage = () => {

    return (
        <div className='user-message'>
            <p><span>Our records show that you are a first time Heyday client.</span> Please book a Laser Lite + Consultation appointment first, so we can analyze your skin and determine the right laser settings and treatment plan for you.</p>
            <Link to={FACIALS_ROUTE}>Reselect Service</Link>
        </div>
    )
}

export default React.memo(ShowNewUserMessage)
