import { PaymentMethod } from '../../../utils/types/appointmentTypes'
import { FC } from 'react'

interface Props {
  card: PaymentMethod
}
const CardExpireText: FC<Props> = ({ card }) => {
  return (
    <>
      {new Date(card.exp_year, card.exp_month, 1) > new Date() ? (
        <span className="body-1 card-exp">
          Exp. {card.exp_month}/
          {card.exp_year
            .toString()
            .substring(card.exp_year.toString().length - 2)}
        </span>
      ) : (
        <span className="exp-text">Card expired</span>
      )}
    </>
  )
}
export default CardExpireText
