import { Navigate, Route, Routes } from 'react-router-dom'
import { lazy, useEffect } from 'react'
// interface
import IRouteItem from 'interface/router'
import {
  LOCATIONS_ROUTE,
  MEMBERSHIP_CONFIRMATION_ROUTE,
  MEMBERSHIP_EXISTING_USER,
  MEMBERSHIP_NO_APPOINTMENT,
  MEMBERSHIP_UPCOMING_APPOINTMENT,
  MEMBERSHIP_LOCATIONS_ROUTE,
} from '../utils/constants/Helpers'
import PageNotFound from 'views/components/page-not-found'

const AppointmentConfirmation = lazy(
  () => import('views/pages/membershipAppointmentConfirmation')
)
const ExistingMember = lazy(() => import('views/pages/existingMember'))
const NoAppointment = lazy(() => import('views/pages/membershipNoAppointment'))
const UpComingAppointment = lazy(
  () => import('views/pages/membershipUpcomingAppointment')
)
const LocationSelection = lazy(
  () => import('views/pages/membershipLocationSelection')
)

// define app routes
const membershipRoutes: Array<IRouteItem> = [
  {
    key: 'Membership-root',
    title: 'Membership',
    path: '/',
    component: <Navigate to={MEMBERSHIP_LOCATIONS_ROUTE} />,
  },
  {
    key: 'locations',
    title: 'MemberShipLocations',
    path: LOCATIONS_ROUTE,
    component: <LocationSelection />,
  },
  {
    key: 'confirmation',
    title: 'MemberShip Appointment Confirmation',
    path: MEMBERSHIP_CONFIRMATION_ROUTE,
    component: <AppointmentConfirmation />,
  },
  {
    key: 'existing-user',
    title: 'Existing User',
    path: MEMBERSHIP_EXISTING_USER,
    component: <ExistingMember />,
  },
  {
    key: 'no-appointment',
    title: 'No Appointment',
    path: MEMBERSHIP_NO_APPOINTMENT,
    component: <NoAppointment />,
  },
  {
    key: 'upcoming-appointment',
    title: 'Upcoming Appointment',
    path: MEMBERSHIP_UPCOMING_APPOINTMENT,
    component: <UpComingAppointment />,
  },
]

export const MembershipRouter = () => {
  useEffect(() => {
    document.title = 'Heyday | Membership'
  }, [])

  return (
    <Routes>
      {membershipRoutes.map((route) => {
        return (
          <Route
            path={route.path}
            element={route.component}
            key={route.key}
            caseSensitive={true}
          />
        )
      })}
      <Route key="404" path="*" element={<PageNotFound />} />
    </Routes>
  )
}
