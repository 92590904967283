import { useContext, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-rounded.svg'
import { AppContext } from 'providers/context/App'
import { useSnackbar } from 'notistack'
import { getMembershipAgreementPortableText } from 'services/Membership'
import {
  MEMBERSHIP_CANCEL_NOTE,
  MEMBERSHIP_DATA_LIST,
  MEMBERSHIP_MESSAGES,
} from 'utils/constants/Messages'
import AppointmentSubtotal from './appointment-subtotal'
import PortableText from '../portable-text'
import ScrollToTop from '../scroll-to-top'

type Props = {
  actualTotal: number
}

const MembershipUpsell = ({ actualTotal }: Props) => {
  const { appMasterData, updateData } = useContext(AppContext)
  const [membershipAgreement, setMembershipAgreement] = useState<boolean>(false)
  const [membershipPortableText, setMembershipPortableText] = useState<
    object[] | null
  >(null)
  const toggleMembershipAgreement = () => {
    setMembershipAgreement((prev) => !prev)
  }
  const [expanded, setExpanded] = useState(false)
  const toggleExpand = () => {
    setExpanded((prev) => !prev)
  }
  const [upsellTotal, setUpsellTotal] = useState(
    appMasterData.membershipData.listPrice
  )
  const [benifitAmount, setBenifitAmount] = useState(
    Math.max(actualTotal - upsellTotal, 0)
  )

  const { enqueueSnackbar } = useSnackbar()
  const isMember = appMasterData.userInfo.is_member
  const purchasedMembership = appMasterData.cart.purchasedMembership

  // removing body scroll bar when membership agreement is open
  useEffect(() => {
    if (membershipPortableText) {
      if (membershipAgreement) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }, [membershipAgreement, membershipPortableText])

  // On guest state if user is added membership and logged in as membership account then remove the membership
  useEffect(() => {
    if (isMember && purchasedMembership) {
      updateData({
        cart: {
          ...appMasterData.cart,
          purchasedMembership: false,
        },
      })
      enqueueSnackbar(MEMBERSHIP_MESSAGES.ALREADY_MEMBER, {
        variant: 'error',
      })
    }
  }, [
    isMember,
    appMasterData.cart,
    enqueueSnackbar,
    purchasedMembership,
    updateData,
  ])

  useEffect(() => {
    ;(async () => {
      const portableTextResponse = await getMembershipAgreementPortableText()
      setMembershipPortableText(portableTextResponse)
    })()
  }, [])

  useEffect(() => {
    setBenifitAmount(Math.max(actualTotal - upsellTotal, 0))
  }, [upsellTotal, actualTotal])

  const addMembership = async () => {
    updateData({
      cart: {
        ...appMasterData.cart,
        purchasedMembership: true,
      },
    })
    enqueueSnackbar(MEMBERSHIP_MESSAGES.AGREEMENT_SIGNED, {
      variant: 'success',
    })
    toggleExpand()
  }

  // Don't show upsell module if they already agreed or have membership
  if (isMember || purchasedMembership || appMasterData.isUTMExist) {
    return null
  }

  return (
    <div className="upsell-wrapper">
      <div className="upsell-head">
        <h3>Save ${benifitAmount / 100} by becoming a member today.</h3>
        {expanded && (
          <div onClick={toggleExpand} onKeyDown={toggleExpand}>
            <CloseIcon />
          </div>
        )}
      </div>

      {/* User is on non-expanded state, before learn more button click */}
      {!expanded && (
        <>
          <AppointmentSubtotal isUpsell={true} setTotal={setUpsellTotal} />
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className="app-btn app-btn-outlined more-btn"
            onClick={toggleExpand}
          >
            Learn More
          </Button>
        </>
      )}

      {/* User is on expanded state, after learn more button click */}
      {expanded && (
        <div className="upsell-expanded fade-in">
          <h4>
            Our membership is built to support the skin’s monthly renewal cycle.
            Sign up today to discover your skin’s potential.
          </h4>

          <div className="upsell-benefit-list">
            {MEMBERSHIP_DATA_LIST.map((benefit) => (
              <div className="upsell-benefit-card" key={benefit.title}>
                <img src={benefit.icon} alt={benefit.title} />
                <div>
                  <p>{benefit.title}</p>
                  <p>{benefit.subTitle}</p>
                </div>
              </div>
            ))}
          </div>
          <p>{MEMBERSHIP_CANCEL_NOTE}</p>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className="app-btn app-btn more-btn"
            onClick={addMembership}
          >
            Agree & Add to Cart
          </Button>
          <p className="upsell-expanded-footer">
            By agreeing, you acknowledge the terms of the Heyday Membership
            summarized above, and detailed in the full agreement{' '}
            <span
              onClick={toggleMembershipAgreement}
              onKeyDown={toggleMembershipAgreement}
            >
              here.
            </span>
          </p>
        </div>
      )}

      {/* Membership agreement popup */}
      {membershipAgreement && membershipPortableText && (
        <ScrollToTop>
          <div className="membership-agreement-popup">
            <div className="membership-agreement-wrapper">
              <div
                onClick={toggleMembershipAgreement}
                onKeyDown={toggleMembershipAgreement}
                className="membership-agreement-close-icon"
              >
                <CloseIcon />
              </div>

              <h2>Monthly Membership Agreement</h2>
            </div>
            <div className="membership-portable-text">
              <PortableText content={membershipPortableText} />
            </div>
          </div>
        </ScrollToTop>
      )}
    </div>
  )
}

export default MembershipUpsell
