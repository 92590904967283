import { FACIAL_SERVICES_DESCRIPTION } from 'utils/constants/Helpers'

export default function FacialHeader() {
  return (
    <div className="facial-header">
      <h2>Select a Service</h2>
      <p>{FACIAL_SERVICES_DESCRIPTION}</p>
    </div>
  )
}
