import React from 'react'
import './content.scss'

const Content = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="main">
      <div className="main-box">{children}</div>
    </div>
  )
}

export default Content
