import { AppContext } from 'providers/context/App'
import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import {
  MEMBERSHIP_MAX_FACIAL_DISCOUNT,
  MEMBERSHIP_LASER_FACIAL_PRICE,
} from 'utils/constants/Helpers'
import { formattedCost, getFacialType } from 'utils/helper-functions'
import { AppData } from 'utils/types/appTypes'

type Props = {
  setTotal: Dispatch<SetStateAction<number>>
  isUpsell?: boolean
}

const AppointmentSubtotal = ({ isUpsell, setTotal }: Props) => {
  const { appMasterData } = useContext(AppContext)
  const isLaserFacial = appMasterData.isLaserFacial
  const isMember = appMasterData.userInfo.is_member
  const purchasedMembership = appMasterData.cart.purchasedMembership
  const hasFreeFacialVoucher = !!appMasterData.membershipVouchers.find(
    (obj) => obj.service.name === '50 Minute Facial' && obj.quantity > 0
  )
  const isLaserConsultation =
    appMasterData.selectedFacial.toLowerCase() === 'laser lite + consultation'

  // calculating subtotal when enhacement removed or membership removed
  useEffect(() => {
    getSubTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appMasterData.selectedEnhancements,
    appMasterData.cart.purchasedMembership,
  ])

  const getSubTotal = () => {
    // if upsell or purchased, include membership price in total
    const membershipCost =
      isUpsell || purchasedMembership
        ? appMasterData.membershipData.listPrice
        : 0
    // Facial price
    let facialPrice = appMasterData.category.categoryObj?.listPrice as number

    // Enhancement total price
    let enhancementsPrice = appMasterData.selectedEnhancements.reduce(
      (acc, curr) => acc + (curr.listPrice ?? 0),
      0
    )

    // if member, change the prices accordingly
    if (isMember && !isLaserFacial) {
      enhancementsPrice = enhancementsPrice / 2
      facialPrice = facialPrice - MEMBERSHIP_MAX_FACIAL_DISCOUNT
    }

    // if upsell or purchased, facial price goes to 0 and enhancement are of members price
    if (isUpsell || purchasedMembership) {
      facialPrice = 0
      enhancementsPrice = enhancementsPrice / 2
    }

    // take discount amount directly from cart
    const discountPrice = appMasterData.cart.summary.discountAmount

    const subTotal =
      membershipCost + facialPrice + enhancementsPrice - discountPrice

    setTotal(subTotal)

    return subTotal
  }

  // Monthly membership price. Only show if upsell or purchased
  const renderMembershipPrice = (
    appMasterData: AppData,
    isUpsell: boolean | undefined
  ) => {
    if (purchasedMembership || isUpsell) {
      return (
        <div className="price-item">
          <span className="item-text">Monthly Membership</span>
          <div>
            <span className="cost mono-1">
              {formattedCost(appMasterData.membershipData.listPrice)}
            </span>
          </div>
        </div>
      )
    }
    return null
  }

  // Facial price line, if member or upsell or purchased, show discounted price as well
  const renderFacialPrice = (
    appMasterData: AppData,
    isLaserFacial: boolean,
    isUpsell: boolean | undefined,
    purchasedMembership: boolean
  ) => {
    const getServicePrice = () => {
      if (!isLaserFacial && !isLaserConsultation) {
        return formattedCost(
          Math.max(
            (appMasterData.category.categoryObj?.listPrice as number) -
              MEMBERSHIP_MAX_FACIAL_DISCOUNT,
            8500
          )
        )
      } else if (isLaserFacial && !isLaserConsultation) {
        return formattedCost(MEMBERSHIP_LASER_FACIAL_PRICE, true, 1)
      }
    }

    return (
      <div className="price-item">
        <span
          className={`item-text ${purchasedMembership && 'strike-through'}`}
        >
          {getFacialType(appMasterData.selectedFacial)}
        </span>
        <div>
          <span
            className={`cost mono-1 ${
              ((isMember && !isLaserConsultation) ||
                isUpsell ||
                purchasedMembership) &&
              'strike-through'
            }`}
          >
            {formattedCost(
              appMasterData.category.categoryObj?.listPrice as number
            )}
          </span>
          {(isUpsell || purchasedMembership) && (
            <span className="cost mono-1 member-price">{formattedCost(0)}</span>
          )}
          {!isUpsell && !purchasedMembership && isMember && (
            <span className="cost mono-1 member-price success">
              {/* reduce upto $31 from facial price for members. minimum price should be $85. */}
              {/* facial price - $31 OR $85. whichever is higher */}
              {getServicePrice()}
            </span>
          )}
        </div>
      </div>
    )
  }

  // Enhancements price list, if member or upsell or purchased, show discounted price as well
  const renderEnhancementsPriceList = (
    appMasterData: AppData,
    isLaserFacial: boolean,
    isMember: boolean,
    isUpsell: boolean | undefined,
    purchasedMembership: boolean
  ) => {
    return (
      <>
        {!isLaserFacial &&
          appMasterData.selectedEnhancements.map((item) => (
            <div className="price-item" key={item.id}>
              <span className="item-text">
                {item.titleVisible || item.name}
              </span>
              <div>
                <span
                  className={`cost mono-1 ${
                    isMember || isUpsell || purchasedMembership
                      ? 'strike-through'
                      : ''
                  }`}
                >
                  {formattedCost(item.listPrice)}
                </span>
                {(isMember || isUpsell || purchasedMembership) && (
                  <span className="cost mono-1 member-price success">
                    {formattedCost(item.listPrice / 2)}
                  </span>
                )}
              </div>
            </div>
          ))}
      </>
    )
  }

  // Membership benefit. remaining amount to be shown as discount to make the price of facial as per requirements
  const renderMembershipBenefit = (
    appMasterData: AppData,
    isMember: boolean,
    isLaserFacial: boolean,
    hasFreeFacialVoucher: boolean
  ) => {
    return (
      <>
        {isMember && !isLaserConsultation && (
          <div className="price-item">
            <span className="item-text success">Member Benefit {!isLaserFacial && 'Applied!'}</span>
            {isLaserFacial && (
              <div>
                <span className="cost mono-1 member-price success">
                  40% savings
                </span>
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  // Promo code discount line, show as received from getCart call
  const renderPromoCodeDiscountLine = (appMasterData: AppData) => {
    return (
      <>
        {appMasterData.cart.isPromoCodeApplied && (
          <div className="price-item">
            <span className="item-text success">
              {appMasterData.cart.promoCodes.map((d) => d.code).join(', ')}
            </span>
            <span className="cost mono-1 success">
              -{formattedCost(appMasterData.cart.summary.discountAmount)}
            </span>
          </div>
        )}
      </>
    )
  }

  // Total line, show only if more then one lines from above is shown
  const renderTotalLine = (
    appMasterData: AppData,
    isUpsell: boolean | undefined,
    purchasedMembership: boolean
  ) => {
    return (
      <>
        {(!!appMasterData.selectedEnhancements.length ||
          (isMember && !isLaserFacial) ||
          isUpsell ||
          purchasedMembership ||
          appMasterData.cart.isPromoCodeApplied) && (
          <div className="price-item total">
            <span
              className={`item-text ${
                (isUpsell || purchasedMembership) && 'success'
              }`}
            >
              {!purchasedMembership && isUpsell ? 'Member Price' : 'Total'}
            </span>
            <span className={`cost mono-1 success`}>
              {formattedCost(Math.max(getSubTotal(), 0))}
            </span>
          </div>
        )}
      </>
    )
  }

  // total amount to be paid on checkout. only membership purchase is charged on card on checkout
  const renderAmountPaidToday = (
    appMasterData: AppData,
    purchasedMembership: boolean
  ) => {
    if (purchasedMembership) {
      return (
        <>
          <div className="price-item">
            <span className="item-text">Amount Paid Today</span>
            <div>
              <span className="cost mono-1">
                {formattedCost(appMasterData.membershipData.listPrice)}
              </span>
            </div>
          </div>
          <span className="body-2 note">
            Additional enhancement charges will be processed after your
            appointment.
          </span>
        </>
      )
    }
    return null
  }

  return (
    <div>
      {renderMembershipPrice(appMasterData, isUpsell)}
      {renderFacialPrice(
        appMasterData,
        isLaserFacial,
        isUpsell,
        purchasedMembership
      )}
      {renderEnhancementsPriceList(
        appMasterData,
        isLaserFacial,
        isMember,
        isUpsell,
        purchasedMembership
      )}
      {renderMembershipBenefit(
        appMasterData,
        isMember,
        isLaserFacial,
        hasFreeFacialVoucher
      )}
      {renderPromoCodeDiscountLine(appMasterData)}
      {renderTotalLine(appMasterData, isUpsell, purchasedMembership)}
      {renderAmountPaidToday(appMasterData, purchasedMembership)}
    </div>
  )
}

export default AppointmentSubtotal
