import { GRAPHQL_ENDPOINT } from '../utils/constants/EndpointUrls'
import { AXIOS_METHODS } from '../utils/constants/Enums'
import { Axios } from './Axios'
import { getLocalstorageItem } from '../utils/helper-functions'
import { BOULEVARD_PUBLIC_API_TOKEN } from '../utils/constants/Helpers'
import {
  AddCartOfferPayload,
  AddCartOfferResponse,
  RemoveCartOfferPayload,
  RemoveCartOfferResponse,
} from '../utils/types/promoCodeTypes'

/*
 * Mutations
 */

const addOfferToCartMutation = `mutation AddCartOffer($input: AddCartOfferInput!){
    addCartOffer(input: $input) {
      cart {
        id
        summary {
          discountAmount
          gratuityAmount
          paymentMethodRequired
          subtotal
          taxAmount
          total
          depositAmount
          deposit
        }
      }
      offer {
        applied
        code
        id
        name
      }
    }
  }`

const removeOfferFromCartMutation = `mutation RemoveCartOffer($input: RemoveCartOfferInput!){
    removeCartOffer(input: $input) {
      cart {
        id
        summary {
          discountAmount
          gratuityAmount
          paymentMethodRequired
          subtotal
          taxAmount
          total
          depositAmount
          deposit
        }
      }
    }
  }`

/*
 * API calls
 */

export const addOfferToCart = async (cartId: string, offerCode: string) => {
  const headers = {
    Authorization: `Basic ${getLocalstorageItem(BOULEVARD_PUBLIC_API_TOKEN)}`,
  }
  const payload: AddCartOfferPayload = {
    query: addOfferToCartMutation,
    variables: {
      input: {
        id: cartId,
        offerCode,
      },
    },
  }
  const response = await Axios.setHeaders(headers).callApi<
    AddCartOfferPayload,
    AddCartOfferResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response
  } else {
    throw new Error(response.message)
  }
}

export const removeOfferFromCart = async (cartId: string, offerId: string) => {
  const headers = {
    Authorization: `Basic ${getLocalstorageItem(BOULEVARD_PUBLIC_API_TOKEN)}`,
  }
  const payload: RemoveCartOfferPayload = {
    query: removeOfferFromCartMutation,
    variables: {
      input: {
        id: cartId,
        offerId,
      },
    },
  }
  const response = await Axios.setHeaders(headers).callApi<
    RemoveCartOfferPayload,
    RemoveCartOfferResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
