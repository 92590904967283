import { useContext, useEffect, useRef, useState } from 'react'
import CommonHeader from '../../components/common-header'
import { ReactComponent as MapPinIcon } from '../../../assets/icons/map-pin-1.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/banner-close.svg'
import './estheticianList.scss'
import Filter from '../../components/filter'
import { AppContext } from '../../../providers/context/App'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EstheticianListProvider from '../../../providers/context/EstheticianList'
import CalendarView from '../../components/calendar-view'
import EstheticianTimeslots from '../../components/esthetician-timeslots'
import { HeaderTheme } from '../../../utils/types/appTypes'
import {
  ENHANCEMENT_ROUTE,
  FACIALS_ROUTE,
  LOCATIONS_ROUTE,
} from '../../../utils/constants/Helpers'
import { TrackPage } from '../../../services/Analytics'
import { RefClickObject } from '../../../utils/types/estheticianTypes'
import SpiralCalendar from '../../../assets/images/spiral_calendar.png'
import { getBannerText, getFacialType } from '../../../utils/helper-functions'

const EstheticianList = () => {
  const { appMasterData } = useContext(AppContext)
  const childRef = useRef<RefClickObject>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const openFilter = () => {
    if (childRef.current !== null) {
      childRef.current.handleFilterBtnClick()
    }
  }
  const [bannerText, setBannerText] = useState('')
  const [isBannerShow, setIsBannerShow] = useState(false)
  const [isAnimationShow, setIsAnimationShow] = useState(false)
  const [isFilterShow, setIsFilterShow] = useState(true)
  const isLaserFacial = appMasterData.isLaserFacial
  const isDermaplaning = !!appMasterData.selectedEnhancements?.find(
    (sE) => sE.name === 'Dermaplaning'
  )
  useEffect(() => {
    TrackPage(location.pathname)
    const bannerData = getBannerText(appMasterData.location)
    if (bannerData) {
      setBannerText(bannerData)
      setIsAnimationShow(true)
      setIsBannerShow(true)
    }
    // Hide filters if Ghost booking is enabled, or if Dermaplaning is added, or if the base service is a laser facial.
    if (
      appMasterData.location.enableGhostBooking ||
      isDermaplaning ||
      isLaserFacial
    )
      setIsFilterShow(false)
    if (!appMasterData.location.locationId) {
      navigate(LOCATIONS_ROUTE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backToFunction = () => {
    const routeToNavigate = isLaserFacial ? FACIALS_ROUTE : ENHANCEMENT_ROUTE
    appMasterData.availableEnhancements.length
      ? navigate(routeToNavigate)
      : navigate(LOCATIONS_ROUTE)
  }

  const handleOnFinish = () => {
    setIsAnimationShow(true)
  }

  return (
    <EstheticianListProvider>
      <div className="esth-list-container">
        <CommonHeader
          backToFunction={backToFunction}
          theme={HeaderTheme.LIGHT}
        />
        <p className="select-location">
          {getFacialType(appMasterData.selectedFacial)} at
          <MapPinIcon className="map-icon" />
          {isLaserFacial ? (
            <span className="sub-head laser-sub-head">
              {appMasterData.location.name}
            </span>
          ) : (
            <Link to={LOCATIONS_ROUTE} className="sub-head">
              {appMasterData.location.name}
            </Link>
          )}
        </p>
        {isFilterShow && <Filter ref={childRef} />}
        <CalendarView />
        <EstheticianTimeslots
          openFilter={openFilter}
          onFinish={handleOnFinish}
          showFilter={isFilterShow}
        />
      </div>
      {isBannerShow && isAnimationShow && (
        <div className="offer-banner cssanimation fadeInBottom">
          <div className="massage">
            {bannerText}{' '}
            <img
              src={SpiralCalendar}
              alt="spiral_calender"
              className="calender-icon"
              loading="lazy"
            />
          </div>
          <CloseIcon onClick={() => setIsBannerShow(false)} />
        </div>
      )}
    </EstheticianListProvider>
  )
}

export default EstheticianList
