import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  CUSTOMER_ACCESS_TOKEN,
  CUSTOMER_SESSION_TOKEN,
} from '../../utils/constants/Helpers'
import { removeLocalstorageItem } from '../../utils/helper-functions'
import { AppContext } from './App'

export interface IErrorContextProp {
  updateErrorData: (_: Partial<ErrorInitialData>) => void
}
export interface ErrorInitialData {
  statusCode?: number
}

const initialState = {
  statusCode: 0,
}
export const ErrorContext = createContext<IErrorContextProp>({
  updateErrorData: () => {},
})

const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const { updateData } = useContext(AppContext)

  const [errorData, setErrorData] = useState<ErrorInitialData>(initialState)

  const updateErrorData = (errStateUpdate: Partial<ErrorInitialData>) => {
    setErrorData(errStateUpdate)
  }

  useEffect(() => {
    if (errorData.statusCode === 401) {
      removeLocalstorageItem(CUSTOMER_ACCESS_TOKEN)
      removeLocalstorageItem(CUSTOMER_SESSION_TOKEN)
      updateData({ user: { token: '' } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorData])

  const value = useMemo(() => {
    return {
      updateErrorData,
    }
  }, [])

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}

export default ErrorProvider
