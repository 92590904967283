import { FC, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HeaderTheme } from '../../../utils/types/appTypes'
import CommonHeader from '../../components/common-header'
import HealthCheckFooter from '../../components/health-check/health-check-footer'
import HealthCheckContent from '../../components/health-check/health-check-content'
import { HEALTH_CHECK } from '../../../utils/constants/Enums'
import {
  APPOINTMENT_CONFIRMATION_ROUTE,
  ESTHETICIAN_LIST_ROUTE,
  LASER_APPOINTMENT_CONFIRMATION_ROUTE,
  LASER_ESTHETICIAN_LIST_ROUTE,
  LOCATIONS_ROUTE,
} from '../../../utils/constants/Helpers'
import { TrackPage } from '../../../services/Analytics'
import { AppContext } from '../../../providers/context/App'
import { getUserAppointments, getUserInfo } from '../../../services/User'
import { ErrorContext } from '../../../providers/context/Error'

const HealthCheck: FC = () => {
  const [healthCheck, setHealthCheck] = useState<HEALTH_CHECK>(
    HEALTH_CHECK.NULL
  )
  const navigate = useNavigate()
  const location = useLocation()
  const { appMasterData, updateData } = useContext(AppContext)
  const { updateErrorData } = useContext(ErrorContext)

  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo()
      const userAppointment = await getUserAppointments()
      updateData({ previousBookedAppointments: userAppointment })
      return { success: true, data: response }
    } catch (error: any) {
      updateErrorData(error)
      return { success: false }
    }
  }

  useEffect(() => {
    TrackPage(location.pathname)
    ;(async () => {
      let res = await fetchUserInfo()
      if (res.success) {
        updateData({ userInfo: res.data })
      }
    })()

    if (!appMasterData.location.locationId) {
      navigate(LOCATIONS_ROUTE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backToFunction = () => {
    navigate(
      appMasterData.isLaserFacial
        ? LASER_ESTHETICIAN_LIST_ROUTE
        : ESTHETICIAN_LIST_ROUTE
    )
  }

  const navigateToAppointment = () => {
    navigate(
      appMasterData.isLaserFacial
        ? LASER_APPOINTMENT_CONFIRMATION_ROUTE
        : APPOINTMENT_CONFIRMATION_ROUTE
    )
  }

  return (
    <>
      <CommonHeader
        backToFunction={backToFunction}
        theme={HeaderTheme.NEUTRAL_TERTIARY}
      />
      <div className="content-body tertiary-background health-body-wrapper">
        <HealthCheckContent healthCheck={healthCheck} />
      </div>
      <HealthCheckFooter
        healthCheck={healthCheck}
        setHealthCheck={setHealthCheck}
        navigateToAppointment={navigateToAppointment}
        navigateToDateSelection={backToFunction}
      />
    </>
  )
}

export default HealthCheck
