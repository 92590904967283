import Visa from '../../assets/icons/card-brands/visa.svg'
import MasterCard from '../../assets/icons/card-brands/master-card.svg'
import Amex from '../../assets/icons/card-brands/amex.svg'
import DinersClub from '../../assets/icons/card-brands/diners-club.svg'
import Discover from '../../assets/icons/card-brands/discover.svg'
import Jcb from '../../assets/icons/card-brands/jcb.svg'
import UnionPay from '../../assets/icons/card-brands/union-pay.svg'
import Maestro from '../../assets/icons/card-brands/maestro.svg'
import Elo from '../../assets/icons/card-brands/elo.svg'
import ApplePay from '../../assets/icons/card-brands/apple-pay.svg'
import GiftCard from '../../assets/icons/card-brands/gift-card.svg'
import GooglePay from '../../assets/icons/card-brands/google-pay.svg'
import VisaElectron from '../../assets/icons/card-brands/visa-electron.svg'
import creditCardType from 'credit-card-type'

const cardConfig: { [key: string]: string } = {
  visa: Visa,
  mastercard: MasterCard,
  master: MasterCard,
  amex: Amex,
  'american-express': Amex,
  'american express': Amex,
  'diners-club': DinersClub,
  'diners club': DinersClub,
  diners: DinersClub,
  discover: Discover,
  jcb: Jcb,
  unionpay: UnionPay,
  union: UnionPay,
  maestro: Maestro,
  mir: '',
  elo: Elo,
  hiper: '',
  hipercard: '',
  'apple-pay': ApplePay,
  'apple pay': ApplePay,
  'gift-card': GiftCard,
  'gift card': GiftCard,
  'google-pay': GooglePay,
  'google pay': GooglePay,
  'visa-electron': VisaElectron,
  'visa electron': VisaElectron,
  unknown: '',
}

const cardType: { [key: string]: string } = {
  visa: 'VISA',
  mastercard: 'MASTER',
  'american express': 'AMEX',
  jcb: 'JCB',
  'diners club': 'DINERS',
  maestro: 'MAESTRO',
  unionpay: 'UNION',
}

export const getBrandIcon = (cardNumber: string) => {
  const card = creditCardType(cardNumber)
  if (card.length > 0 && !!cardConfig[card[0].type]) {
    return cardConfig[card[0].type]
  } else {
    return ''
  }
}

export const getBrandName = (cardNumber: string) => {
  const card = creditCardType(cardNumber)
  if (!!card[0] && !!card[0].niceType) {
    return card[0].niceType
  } else {
    return ''
  }
}

export const getBrandNameConverter = (cardName: string) => {
  return cardType[cardName.toLowerCase()]
    ? cardType[cardName.toLowerCase()]
    : 'UNKNOWN'
}

export const getBrandImage = (cardName: string) => {
  return cardConfig[cardName.toLowerCase()]
    ? cardConfig[cardName.toLowerCase()]
    : cardConfig['unknown']
}
