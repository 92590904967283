import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'

import './dialog.scss'

interface DialogProps {
  isModalOpen: boolean
  handleClose: () => void
  children: React.JSX.Element
}
const CustomDialog: FC<DialogProps> = ({
  isModalOpen,
  handleClose,
  children,
}) => {
  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {children}
      </Dialog>
    </div>
  )
}

export default CustomDialog
