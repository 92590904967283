import { chain } from 'lodash'
import { LocationPlace, LocationsRes } from '../utils/types/locationTypes'
import { LOCATION_STATE_CONFIG } from '../utils/constants/Helpers'
import SanityClient from 'services/sanityClient'

const locationQuery = `*[_type == "location" && isBookable == true && !(_id in path('drafts.**'))]{
  id,
  name,
  timezone,
  openDate,
  "mapImg": featuredImage.asset->url,
  enableMembershipFlow,
  enableGroupBooking,
  enableGhostBooking,
  advanceBookingLimit,
  address,
  marketingSupport{
    ...,
    confirmationPromo{
      headline,
      subheading,
      offerCode,
      'bannerColor': bgColor.hex,
      'textColor': textColor.hex
    }
  },
  contact{
    ...,
    availability[]{
      day,
      availableTimes[]{
        from,
        to
      }
    }
  },
}`

const formatData = (locationData: LocationPlace[]): LocationsRes[] => {
  const result = chain(locationData)
    .groupBy('address.state')
    .map((value: LocationPlace[], key: string) => {
      return {
        locationName: LOCATION_STATE_CONFIG[key],
        locationId: value[0].id,
        places: value,
      }
    })
    .value()
  result.sort((a, b) => {
    const locationOne = a.locationName
    const locationTwo = b.locationName
    if (locationOne > locationTwo) {
      return 1
    }
    if (locationOne < locationTwo) {
      return -1
    }
    return 0
  })

  return result
}

export const getLocations = async (formatFn = formatData) => {
  const response: LocationPlace[] = await SanityClient.fetch(
    locationQuery
  ).catch(console.error)
  if (response) {
    return formatFn(response)
  } else {
    console.error(response)
    throw new Error(response)
  }
}
