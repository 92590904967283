import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../../providers/context/App'
import { PurchaseAnalytics, TrackPage } from '../../../services/Analytics'
import { LOCATIONS_ROUTE } from '../../../utils/constants/Helpers'

import { HeaderTheme } from '../../../utils/types/appTypes'
import CommonHeader from '../../components/common-header'
import ConfirmedAppointmentContent from '../../components/confirmed-appointment'

type LocationState = {
  isFromShareAppointment?: boolean
}

const ConfirmedAppointment = () => {
  const location = useLocation()
  const { appMasterData } = useContext(AppContext)
  const navigate = useNavigate()

  const backToFunction = () => {
    navigate(LOCATIONS_ROUTE)
  }

  useEffect(() => {
    if (!(location.state as LocationState)?.isFromShareAppointment) {
      TrackPage(location.pathname)
      PurchaseAnalytics(appMasterData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CommonHeader backToFunction={backToFunction} theme={HeaderTheme.LIGHT} />
      <ConfirmedAppointmentContent />
    </>
  )
}

export default ConfirmedAppointment
