import React, { FC, ReactElement } from 'react'
import {
  CANCELLATION_POLICY,
  COVID_19_POLICY,
} from '../../../utils/constants/StaticLinks'
import './appointment.scss'

const AppointmentFooter: FC<{}> = (): ReactElement => {
  return (
    <div className="body-3 policy-text">
      By confirming your appointment you’re agreeing to our{' '}
      <a href={COVID_19_POLICY} target="_blank" rel="noreferrer">
        COVID-19
      </a>{' '}
      and{' '}
      <a href={CANCELLATION_POLICY} target="_blank" rel="noreferrer">
        Cancellation Policy
      </a>
      .
    </div>
  )
}

export default React.memo(AppointmentFooter)
