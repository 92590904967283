import React, { useContext } from 'react'
import { AppContext } from '../../../providers/context/App'
import { getLocationBanner } from '../../../utils/helper-functions/locationBanner'
import './appointment.scss'

const AppointmentBanner = () => {
  const { appMasterData } = useContext(AppContext)
  return (
    <img
      src={getLocationBanner(appMasterData.location.name)}
      className="heyday-banner"
      alt="heyday location"
      loading="lazy"
    />
  )
}

export default React.memo(AppointmentBanner)
