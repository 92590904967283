import { RETURNING_NON_MEMBER_BANNER_TEXT } from "utils/constants/Messages"

const ReturningNonMemberBanner = () => {
    return (
        <div className='non-member-banner'>
            {RETURNING_NON_MEMBER_BANNER_TEXT}
        </div>
    )
}
export default ReturningNonMemberBanner
