import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { styled } from '@mui/material/styles'
import { Avatar, Box, Button, Chip, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ReactComponent as HIcon } from '../../../assets/icons/H.svg'
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/X.svg'
import './filter.scss'
import { generateAvatarText } from '../../../utils/helper-functions'
import { EstheticianListContext } from '../../../providers/context/EstheticianList'
import { Esthetician } from '../../../utils/types/estheticianTypes'
import moment from 'moment'
import { AppContext } from '../../../providers/context/App'

const StyledMenu: any = styled((props) => (
  <Menu
    open={true}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: '100%',
    maxWidth: 502,
    maxHeight: 360,
    border: '1px solid var(--neutral-secondary-darker)',
    boxShadow:
      '6px 8px 12px rgba(0, 0, 0, 0.04), 1px 1px 4px rgba(0, 0, 0, 0.08), 4px 6px 8px rgba(0, 0, 0, 0.06)',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      borderBottom: '1px solid var(--tin)',
      padding: 16,
    },
  },
}))

const FilterComponent = forwardRef((props, ref): ReactElement => {
  const { estheticianMasterData, updateData: estheticianUpdateData } =
    useContext(EstheticianListContext)
  const { appMasterData, updateData: filterDataUpdate } = useContext(AppContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedEsthetician, setSelectedEsthetician] = useState<
    Array<Esthetician>
  >([...(appMasterData?.filteredEstheticians ?? [])])

  const open = Boolean(anchorEl)
  const filterBtnRef = useRef<HTMLButtonElement>(null)

  const handleFilterBtnClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: any) => {
    setAnchorEl(null)
  }
  const handleDelete = (esthetician: Esthetician) => {
    setSelectedEsthetician(
      selectedEsthetician.filter((esth) => esth.id !== esthetician.id)
    )
  }

  const handleEstheticianClick = (event: any, esthetician: Esthetician) => {
    if (!selectedEsthetician.some((esth) => esth.id === esthetician.id)) {
      setSelectedEsthetician([...selectedEsthetician, esthetician])
    } else {
      setSelectedEsthetician(
        selectedEsthetician.filter((esth) => esth.id !== esthetician.id)
      )
    }
  }

  useImperativeHandle(ref, () => ({
    handleFilterBtnClick() {
      if (filterBtnRef.current !== null) {
        window.scrollTo(0, 0)
        filterBtnRef.current.click() //Trigger click
      }
    },
  }))

  useEffect(() => {
    estheticianUpdateData({ filteredEstheticians: selectedEsthetician })
    filterDataUpdate({ filteredEstheticians: selectedEsthetician })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEsthetician])

  return (
    <>
      <div className="filter">
        <Button
          id="filter-button"
          aria-controls={'filter-menu'}
          aria-haspopup="listbox"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          ref={filterBtnRef}
          onClick={handleFilterBtnClick}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          Filter
        </Button>
        <StyledMenu
          className="filter-menu"
          id="filter-menu"
          MenuListProps={{
            'aria-labelledby': 'filter-button',
            role: 'listbox',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {estheticianMasterData.estheticians.map((esthetician) => {
            if (!esthetician.isAny) {
              return (
                <MenuItem
                  key={esthetician.id}
                  onClick={(event) =>
                    handleEstheticianClick(event, esthetician)
                  }
                  className="esthetician-item"
                  disableRipple
                >
                  <Avatar className="name-avatar">
                    {generateAvatarText(
                      esthetician.staff.firstName,
                      esthetician.staff.lastName
                    )}
                  </Avatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="name-text">
                      {esthetician.staff.firstName}{' '}
                      {esthetician.staff.lastName.charAt(0)}
                    </span>
                    {esthetician.lastSeen && (
                      <span className="lastseen-text">
                        Last seen{' '}
                        {moment(esthetician.lastSeen).format('MM/DD/YYYY')}
                      </span>
                    )}
                  </Box>
                  <div className="check-icon">
                    {selectedEsthetician.some(
                      (esth) => esth.id === esthetician.id
                    ) && <CheckmarkIcon />}
                  </div>
                </MenuItem>
              )
            } else {
              return (
                <MenuItem
                  key={esthetician.id}
                  onClick={(event) =>
                    handleEstheticianClick(event, esthetician)
                  }
                  className="esthetician-item"
                  disableRipple
                >
                  <Chip
                    className="any-chip"
                    avatar={
                      <Avatar>
                        <HIcon />
                      </Avatar>
                    }
                    label="Any Esthetician"
                  />
                  <div className="check-icon">
                    {selectedEsthetician.some(
                      (esth) => esth.id === esthetician.id
                    ) && <CheckmarkIcon />}
                  </div>
                </MenuItem>
              )
            }
          })}
        </StyledMenu>
        {/* selected esthetician chips */}
        <Box sx={{ display: 'inline-flex' }} className="selected-pills">
          {selectedEsthetician.map((esthetician) => {
            return (
              <Chip
                key={esthetician.id}
                label={`${esthetician.staff.firstName
                  } ${esthetician.staff.lastName.charAt(0)}`}
                onDelete={() => handleDelete(esthetician)}
                deleteIcon={<CloseIcon className="close-icon" />}
                variant="outlined"
              />
            )
          })}
        </Box>
      </div>
    </>
  )
})

export default FilterComponent
