// valid for authentication
import {
  ForgetPayload,
  ForgetPayloadResponse,
  LoginPayloadVariables,
  LoginSessionPayloadResponse,
  SignupPayload,
  SignUpPayloadResponse,
  SignUpVariable,
  UpdatePhoneNumberVariable,
  verifyEmailPayload,
  verifyEmailResponse,
} from '../utils/types/sessionTypes'
import {
  AUTH_SESSION_URL,
  SHOPIFY_GRAPHQL_URL,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  VERIFY_EMAIL_URL,
  GRAPHQL_ENDPOINT,
  CLIENT_INFO_API,
  BOOKED_APPOINTMENT,
  ACCOUNT_URL,
} from '../utils/constants/EndpointUrls'
import {
  emailVerifier,
  getLocalstorageItem,
  tokenValidator,
} from '../utils/helper-functions'
import { Axios } from './Axios'
import { AXIOS_METHODS } from '../utils/constants/Enums'
import {
  BookedAppointmentResponse,
  ClientInfoResponse,
  MyAppointmentsPayload,
  MyAppointmentsResponse,
  MyMembershipsPayload,
  MyMembershipsResponse,
} from '../utils/types/userTypes'
import {
  BOULEVARD_AUTH_API_TOKEN,
  CUSTOMER_SESSION_TOKEN,
} from '../utils/constants/Helpers'
import { SIGNUP_MESSAGES } from 'utils/constants/Messages'

/*
 * Query
 */

const myAppointmentsQuery = `query MyAppointments($query: QueryString){
  myAppointments(
    first: 2,
    query: $query
  ) {
    edges {
      node {
        id
        startAt
        endAt
        duration
        cancelled
        state
        clientId
        client {
          id
          name
        }
        location {
          id
          name
          businessName
        }
        notes
        appointmentServices {
          price
          startAt
          endAt
          startTimeOffset
          totalDuration
          duration
          staffId
          staff {
            id
            firstName
            lastName
            bio
          }
          serviceId
          service {
            name
            categoryId
            category {
              name
            }
          }
        }
      }
    }
  }
}`

const myMembershipsQuery = `query {
  myMemberships(first: 10) {
    edges {
      cursor
      node {
        termNumber
        startOn
        endOn
        clientId
        client {
          firstName
          lastName
        }
        status
        name
        vouchers {
          quantity
          service {
            id
            name
          }
        }
      }
    }
  }
}`

/*
 * Mutations
 */

const createUserMutation = `mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`

const customerRecoverMutation = `mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}`

/*
 * Api call
 */
export const emailVerification = async (emailId: string) => {
  if (!emailId) return
  const payload = {
    email: emailId,
  }
  const headers = {
    'Content-Type': 'application/json',
  }
  const isEmail = emailVerifier(emailId)
  if (isEmail) {
    const response = await Axios.setHeaders(headers).callApi<
      verifyEmailPayload,
      verifyEmailResponse
    >(AXIOS_METHODS.POST, VERIFY_EMAIL_URL, undefined, payload)
    if (response.data) {
      return response.data
    } else {
      throw new Error(response.message)
    }
  } else {
    throw new Error(SIGNUP_MESSAGES.INVALID_EMAIL)
  }
}

export const signUpCreateUserApi = async (variables: SignUpVariable) => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  }
  const payload: SignupPayload = {
    query: createUserMutation,
    variables: { input: variables },
  }
  const response = await Axios.setHeaders(headers).callApi<
    SignupPayload,
    SignUpPayloadResponse
  >(AXIOS_METHODS.POST, SHOPIFY_GRAPHQL_URL, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const updatePhoneNumber = async (
  authToken: string,
  variables: UpdatePhoneNumberVariable
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getLocalstorageItem(CUSTOMER_SESSION_TOKEN)}`,
  }
  tokenValidator()
  const payload: UpdatePhoneNumberVariable = variables
  const response = await Axios.setHeaders(headers).callApi<
    UpdatePhoneNumberVariable,
    ClientInfoResponse
  >(AXIOS_METHODS.PUT, ACCOUNT_URL, undefined, payload)

  return response.data
}

export const getUserAuthSessionApi = async (
  authToken: string,
  variables: LoginPayloadVariables
) => {
  const payload: LoginPayloadVariables = variables
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${authToken}`,
  }
  const response = await Axios.setHeaders(headers).callApi<
    LoginPayloadVariables,
    LoginSessionPayloadResponse
  >(AXIOS_METHODS.POST, AUTH_SESSION_URL, undefined, payload)
  if (response) {
    return response
  } else {
    throw new Error(response)
  }
}

export const forgetPasswordApi = async (variables: verifyEmailPayload) => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  }
  const payload: ForgetPayload = {
    query: customerRecoverMutation,
    variables,
  }
  const response = await Axios.setHeaders(headers).callApi<
    ForgetPayload,
    ForgetPayloadResponse
  >(AXIOS_METHODS.POST, SHOPIFY_GRAPHQL_URL, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const getMyAppointments = async (query: string) => {
  const headers = {
    Authorization: `Basic ${getLocalstorageItem(BOULEVARD_AUTH_API_TOKEN)}`,
  }
  const payload: MyAppointmentsPayload = {
    query: myAppointmentsQuery,
    variables: {
      query,
    },
  }
  const response = await Axios.setHeaders(headers).callApi<
    MyAppointmentsPayload,
    MyAppointmentsResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const getMyMemberships = async () => {
  const headers = {
    Authorization: `Basic ${getLocalstorageItem(BOULEVARD_AUTH_API_TOKEN)}`,
  }
  const payload = {
    query: myMembershipsQuery,
  }
  const response = await Axios.setHeaders(headers).callApi<
    MyMembershipsPayload,
    MyMembershipsResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const getUserInfo = async () => {
  tokenValidator()
  const headers = {
    Authorization: `Bearer ${getLocalstorageItem(CUSTOMER_SESSION_TOKEN)}`,
  }
  const response = await Axios.setHeaders(headers).callApi<
    undefined,
    ClientInfoResponse
  >(AXIOS_METHODS.GET, CLIENT_INFO_API)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const getUserAppointments = async () => {
  tokenValidator()
  const headers = {
    Authorization: `Bearer ${getLocalstorageItem(CUSTOMER_SESSION_TOKEN)}`,
  }
  const response = await Axios.setHeaders(headers).callApi<
    undefined,
    BookedAppointmentResponse[]
  >(AXIOS_METHODS.GET, `${BOOKED_APPOINTMENT}?recent=true`)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
