import {
  PAYMENT_METHOD_API,
  PAYMENT_TOKENIZE_API,
} from '../utils/constants/EndpointUrls'
import { AXIOS_METHODS } from '../utils/constants/Enums'
import { Axios } from './Axios'
import {
  AddPaymentMethodPayload,
  AddPaymentMethodResponse,
  PaymentMethodResponse,
  PaymentTokenizePayload,
  PaymentTokenizeResponse,
} from '../utils/types/appointmentTypes'
import { getLocalstorageItem, tokenValidator } from '../utils/helper-functions'
import { CUSTOMER_SESSION_TOKEN } from '../utils/constants/Helpers'

export const generatePaymentToken = async (payload: PaymentTokenizePayload) => {
  const headers = {
    Authorization: '',
  }
  const response = await Axios.setHeaders(headers).callApi<
    PaymentTokenizePayload,
    PaymentTokenizeResponse
  >(AXIOS_METHODS.POST, PAYMENT_TOKENIZE_API, undefined, payload)
  if (response) {
    return response
  } else {
    throw new Error('')
  }
}

export const getPaymentMethods = async () => {
  const token = getLocalstorageItem(CUSTOMER_SESSION_TOKEN)
  tokenValidator()
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const response = await Axios.setHeaders(headers).callApi<
    undefined,
    PaymentMethodResponse
  >(AXIOS_METHODS.GET, PAYMENT_METHOD_API)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const addPaymentMethods = async (payload: AddPaymentMethodPayload) => {
  const token = getLocalstorageItem(CUSTOMER_SESSION_TOKEN)
  tokenValidator()
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const response = await Axios.setHeaders(headers).callApi<
    AddPaymentMethodPayload,
    AddPaymentMethodResponse
  >(AXIOS_METHODS.POST, PAYMENT_METHOD_API, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const deletePaymentMethods = async (id: string) => {
  const token = getLocalstorageItem(CUSTOMER_SESSION_TOKEN)
  tokenValidator()
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const response = await Axios.setHeaders(headers).callApi<undefined, boolean>(
    AXIOS_METHODS.DELETE,
    `${PAYMENT_METHOD_API}/${id}`
  )
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
