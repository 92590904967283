import NineteenStreet from '../../assets/images/locations/19th-street.svg'
import CommonLocation from '../../assets/images/locations/Nomad-playavista-bethesda-others.svg'
import Beverly from '../../assets/images/locations/Beverly.svg'
import Noho from '../../assets/images/locations/Noho.svg'
import Rittenhouse from '../../assets/images/locations/Rittenhouse.svg'
import SilverLake from '../../assets/images/locations/Silver-lake.svg'
import Tribeca from '../../assets/images/locations/Tribeca.svg'
import UpperEastSide from '../../assets/images/locations/Upper-east-side.svg'
import UpperWestSide from '../../assets/images/locations/Upper-west-side.svg'

const locationBannerConfig: { [key: string]: string } = {
  '19th Street': NineteenStreet,
  Beverly: Beverly,
  Noho: Noho,
  Rittenhouse: Rittenhouse,
  'Silver Lake': SilverLake,
  Tribeca: Tribeca,
  'Upper East Side': UpperEastSide,
  'Upper West Side': UpperWestSide,
  Other: CommonLocation,
}

export const getLocationBanner = (locationName: string) => {
  return locationBannerConfig[locationName] ?? locationBannerConfig.Other
}
