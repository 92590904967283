import { Facial } from 'utils/types/appTypes'
import FacialCard from './facialCard'

interface FacialListProps {
  facials: Facial[]
  selectedFacial: string
  onSelectFacial: (facial: Facial) => void
}
export default function FacialList({
  facials,
  selectedFacial,
  onSelectFacial,
}: FacialListProps) {
  return (
    <>
      {facials.map((facial) => {
        return (
          <FacialCard
            key={facial.id}
            selectedFacial={selectedFacial}
            facial={facial}
            onSelectFacial={onSelectFacial}
          />
        )
      })}
    </>
  )
}
