import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import CommonHeader from 'views/components/common-header'
import { Facial as FacialType, HeaderTheme } from 'utils/types/appTypes'
import { AppContext } from 'providers/context/App'
import {
  ENHANCEMENT_ROUTE,
  LASER_ESTHETICIAN_LIST_ROUTE,
  LOCATIONS_ROUTE,
} from 'utils/constants/Helpers'
import FacialList from 'views/components/facial/facialList'
import FacialHeader from 'views/components/facial/facialHeader'
import FacialContinueButton from 'views/components/facial/facialContinueButton'
import { services } from 'utils/constants/services'

import './facial.scss'

export default function Facial() {
  const { appMasterData, updateData } = useContext(AppContext)
  const {
    selectedFacial,
    isLaserFacial,
    availableFacials,
    location: selectedLocation,
  } = appMasterData

  const navigate = useNavigate()

  const onSelectFacial = (facial: FacialType) => {
    const facialData = availableFacials.find((fa) => fa.name === facial.name)!
    const category = {
      categoryId: facialData.id,
      categoryObj: facialData,
    }
    const isFacialLaser = services.find(ele => ele.name === facial.name)
    
    if (isFacialLaser?.isLaser && facialData) {
      return updateData({
        isLaserFacial: true,
        selectedFacial: facial.name,
        category,
      })
    }
    updateData({
      isLaserFacial: false,
      selectedFacial: facial.name,
      category,
      selectedEnhancements: [],
    })
  }

  const proceedNextScreen = () => {
    if (isLaserFacial) {
      return navigate(LASER_ESTHETICIAN_LIST_ROUTE)
    }
    navigate(ENHANCEMENT_ROUTE)
  }

  useEffect(() => {
    if (!selectedLocation.locationId) {
      navigate(LOCATIONS_ROUTE)
    }
  }, [selectedLocation, navigate])

  return (
    <section className="facial-section">
      <CommonHeader
        backToFunction={() => navigate(LOCATIONS_ROUTE)}
        theme={HeaderTheme.ORANGE}
      />
      <FacialHeader />
      <div className="facial-content">
        <FacialList
          facials={availableFacials}
          selectedFacial={selectedFacial}
          onSelectFacial={onSelectFacial}
        />
        <FacialContinueButton
          selectedFacial={selectedFacial}
          proceedNextScreen={proceedNextScreen}
        />
      </div>
    </section>
  )
}
