import { createContext, ReactNode, useMemo, useState } from 'react'
import { AppointmentConfirmationData } from '../../utils/types/appointmentTypes'

interface IAppointmentConfContextProp {
  appointmentConfMasterData: AppointmentConfirmationData
  updateData: (_: Partial<AppointmentConfirmationData>) => void
}

const initialAppointmentMasterData = {
  isExistingCard: false,
  selectedCardToken: '',
  newCard: {
    name: '',
    number: '',
    cvv: '',
    exp_month: 0,
    exp_year: 0,
    brand: '',
    zip_code: '',
  },
  newCardDisableStatus: true,
  note: '',
  selectedCard: null,
}

export const AppointmentConfirmationContext =
  createContext<IAppointmentConfContextProp>({
    appointmentConfMasterData: initialAppointmentMasterData,
    updateData: () => {},
  })

const AppointmentConfirmationProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [appointmentConfMasterData, setAppointmentConfMasterData] =
    useState<AppointmentConfirmationData>(initialAppointmentMasterData)

  const updateData = (appStateUpdate: Partial<AppointmentConfirmationData>) => {
    setAppointmentConfMasterData((prev) => ({ ...prev, ...appStateUpdate }))
  }

  const value = useMemo(() => {
    return {
      appointmentConfMasterData,
      updateData,
    }
  }, [appointmentConfMasterData])

  return (
    <AppointmentConfirmationContext.Provider value={value}>
      {children}
    </AppointmentConfirmationContext.Provider>
  )
}

export default AppointmentConfirmationProvider
